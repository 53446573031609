import { Container, Typography } from "@mui/material";
import { ROUTES } from "domain/configuration";
import { ProductName } from "domain/core/types";
import React from "react";
import { useAppSelector } from "store/storeHooks";
import { styled } from "styled-components";
import theme, { COLORS } from "ui/theme";

/**
 * has to use lowercase due to error with navtive DOM element extra props
 * https://stackoverflow.com/questions/62399141/react-does-not-recognize-the-backgroundcolor-prop-on-a-dom-element
 */
const HeaderElement = styled.header<{ backgroundcolor: string }>(
  ({ backgroundcolor }) => ({
    background: backgroundcolor,
    padding: "1.25rem 0 0",
    [theme.breakpoints.up("md")]: {
      padding: "3rem 0 0",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "5.5rem 0 0.75rem",
    },
  }),
);

export const Header = () => {
  const { currentFormStep, productMetadata, selectedProduct } = useAppSelector(
    (store) => store.coreData,
  );

  const getTitleAndBackgroundColor = () => {
    const productTitles = {
      [ProductName.Z_EV]: `EV at home plan ${productMetadata.includeEVcharger ? "and smart charger" : ""}`,
      [ProductName.Z_ELECTRIC_REWARDS]: "Fuel back home plan",
      default: "Power your home",
    };

    const productBackgroundColors = {
      [ProductName.Z_EV]: COLORS.gradientOrange,
      [ProductName.Z_ELECTRIC_REWARDS]: COLORS.gradientOrange,
      default: COLORS.gradientIndigo,
    };

    const productInfo = productTitles[selectedProduct]
      ? {
          title: productTitles[selectedProduct],
          backgroundColor: productBackgroundColors[selectedProduct],
        }
      : {
          title: productTitles.default,
          backgroundColor: productBackgroundColors.default,
        };

    return ["/", ROUTES.address].includes(currentFormStep)
      ? {
          title: productTitles.default,
          backgroundColor: productBackgroundColors.default,
        }
      : productInfo;
  };

  const { title, backgroundColor } = getTitleAndBackgroundColor();

  return (
    <HeaderElement backgroundcolor={backgroundColor}>
      <Container>
        <Typography variant="h1">{title}</Typography>
      </Container>
    </HeaderElement>
  );
};
