import { Container, Link, Typography } from "@mui/material";
import { ROUTES } from "domain/configuration";
import { AddressType, EligibilityType, RateCard } from "domain/core/types";
import { REQUEST_STATUS, RequestStatus } from "lib/types";
import { reportEligibility, sendAnalyticsPageView } from "lib/util";
import React, { useEffect, useMemo } from "react";
import { LoadingWrapper } from "ui/components/LoadingWrapper";
import { ContentWrapper, RowLayout } from "ui/components/StyledComponents";

import { AddressResultDisplay } from "./AddressResultDisplay";
import { PlanChooser } from "./PlanChooser";

interface ConfirmationProps {
  address: AddressType;
  eligibility?: EligibilityType;
  onCheckAnother(): void;
  onEnterManualICP(): void;
  onJoinNowClicked(payload): void;
  rateCard?: RateCard;
  rateCards?: RateCard[];
  fetchStatus: RequestStatus;
  selectedProduct: string;
  firstFormInteraction: boolean;
  setFirstFormInteraction: (value: boolean) => void;
}

const isEligible = (val: string | undefined | null): boolean => {
  if (
    !val ||
    ["upgrade", "not_in_area", "reject", "reject_commercial"].includes(val)
  )
    return false;

  return true;
};

const headerCopies = {
  caseSuccess: "Great, you can get power at",
  caseRejectCommercial:
    "This address appears to be a commercial property, which means we aren't able to connect to it",
  caseNoAddressFound: "Sorry.",
  caseOther:
    "Based on the information about your address we can’t connect with you.",
};

const defaultReturn = (
  <Typography variant="h5">
    We can't give you a price estimate because we can't connect to the power
    meter at that address.
  </Typography>
);

const eligibilityToMessage = {
  upgrade: (
    <Typography variant="h5">
      Unfortunately, it looks like you don't have a smart meter at this address,
      so we can't connect. We suggest you find a retailer that is able to do
      manual meter reads.
    </Typography>
  ),
  not_in_area: (
    <Typography variant="body1">
      Unfortunately we're not currently operating in your network area.
    </Typography>
  ),
  reject_commercial: (
    <Typography variant="h5">
      If you think that this isn't correct, and your address isn't a commercial
      property, please email helpwithpower@z.co.nz or give us a call on 0800 935
      328.
    </Typography>
  ),
  reject: (
    <Typography variant="h5">
      Unfortunately, we currently don't support the meter setup at this address.
    </Typography>
  ),
  export: (
    <Typography variant="h5">
      Unfortunately, we currently don't support the meter setup at this address.
    </Typography>
  ),
};

export const AddressConfirmation: React.FC<ConfirmationProps> = ({
  address,
  eligibility,
  firstFormInteraction,
  rateCards,
  fetchStatus,
  selectedProduct,
  setFirstFormInteraction,
  onCheckAnother,
  onEnterManualICP,
  onJoinNowClicked,
}) => {
  useEffect(() => {
    if (!firstFormInteraction) return;
    reportEligibility(eligibility);
    setFirstFormInteraction(false);
  }, [firstFormInteraction]);

  const resultHeaderCopy = useMemo(() => {
    const { caseSuccess, caseOther, caseRejectCommercial } = headerCopies;
    const eligibilityToHeaderCopy = {
      success: caseSuccess,
      manual: caseSuccess,
      upgrade: caseOther,
      export: caseSuccess,
      not_in_area: caseOther,
      reject: caseOther,
      reject_commercial: caseRejectCommercial,
    };

    if (fetchStatus === "Pending") {
      return <></>;
    }

    if (isEligible(eligibility) && fetchStatus === "Failed") {
      return headerCopies.caseNoAddressFound;
    }

    const headerCopy =
      eligibilityToHeaderCopy[eligibility] || headerCopies.caseNoAddressFound;

    sendAnalyticsPageView(headerCopy);
    return headerCopy;
  }, [eligibility, fetchStatus]);

  const rejectSubHeader = useMemo(() => {
    if (!eligibility) {
      return defaultReturn;
    }

    if (fetchStatus === "Pending" || fetchStatus === "Fulfilled") {
      return <></>;
    }

    if (
      ["success", "manual", "export"].includes(eligibility) &&
      fetchStatus === "Failed"
    ) {
      return defaultReturn;
    }

    return eligibilityToMessage[eligibility] ?? defaultReturn;
  }, [eligibility, fetchStatus, rateCards]);

  const resultSubHeader = useMemo(() => {
    if (
      ["success", "manual", "export"].includes(eligibility) &&
      !["Failed", "Pending"].includes(fetchStatus)
    ) {
      return (
        <PlanChooser
          rateCards={rateCards}
          onJoinNowClicked={onJoinNowClicked}
          route={ROUTES.details}
        />
      );
    }
    return <></>;
  }, [eligibility, fetchStatus, rateCards]);

  const icpSearch = useMemo(() => {
    if (fetchStatus === "Pending") return <></>;

    if (
      !isEligible(eligibility) ||
      (isEligible(eligibility) && fetchStatus === "Failed")
    ) {
      return (
        <Link onClick={onEnterManualICP} component="button">
          Enter ICP manually
        </Link>
      );
    }

    return <></>;
  }, [fetchStatus, eligibility, selectedProduct]);

  return (
    <Container component="main">
      <LoadingWrapper loading={fetchStatus === REQUEST_STATUS.Pending}>
        <ContentWrapper>
          <Typography variant="h2">{resultHeaderCopy}</Typography>
          {rejectSubHeader}
          <RowLayout>
            <AddressResultDisplay
              address={address}
              onCheckAnother={onCheckAnother}
            />
            {icpSearch}
          </RowLayout>
        </ContentWrapper>
        {resultSubHeader}
      </LoadingWrapper>
    </Container>
  );
};
