/* eslint-disable import/named */
/* eslint-disable import/no-unresolved */
// Polyfill for ie11
// eslint-disable-next-line import/no-unresolved
import "react-app-polyfill/stable";
import "./index.css";

import CssBaseline from "@mui/material/CssBaseline";
import {
  // Need to to override MUI base style https://mui.com/material-ui/guides/interoperability/#css-injection-order-2
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { Provider as RollbarProvider } from "@rollbar/react";
import axios from "axios";
import { rollbarConfig } from "lib/rollbarConfig";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "store/configureStore";
import theme from "ui/theme";

import * as serviceWorker from "./serviceWorker";
import { App } from "./ui/App";

const RootApp: React.FC = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_TAGMANAGER_ID || "",
    });
  }, []);

  return (
    <RollbarProvider config={rollbarConfig}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <StyledEngineProvider injectFirst>
              <App />
            </StyledEngineProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </RollbarProvider>
  );
};

const root = createRoot(document.getElementById("root"));

root.render(<RootApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const sumoEndpoint = process.env.REACT_APP_SUMO_LOG_ENDPOINT;

// eslint-disable-next-line max-params
window.onerror = (msg, url, lineNo, columnNo, error) => {
  if (sumoEndpoint == null) {
    console.warn("Logs are not configured correctly");
    return;
  }

  axios({
    method: "POST",
    url: sumoEndpoint,
    data: {
      msg,
      url,
      lineNo,
      columnNo,
      error,
    },
  }).catch((err) => {
    console.warn(err.message);
  });

  return false;
};
