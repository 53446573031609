import { Dialog, DialogContent, Typography } from "@mui/material";
import {
  clearState,
  setCurrentFormStep,
  setPartialSignUpDialogState,
} from "domain/core/reducers";
import { formatAddress, resetStorage } from "lib/util";
import qs from "qs";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { styled } from "styled-components";
import Button from "ui/components/Button";
import { MLink } from "ui/components/StyledComponents";

const Spacer = styled.div`
  height: 1rem;
`;

const SButton = styled(Button)`
  margin: 0.3rem 1rem 0.3rem 0;
`;

const SDialogContent = styled(DialogContent)`
  padding: 2rem 4rem;
`;

const SDialogActions = styled.div`
  display: flex;
`;
export default function PartialSignUpDialog() {
  const dispatch = useAppDispatch();
  const { selectedAddress, partialSignUpDialogOpen, currentFormStep } =
    useAppSelector((store) => store.coreData);

  const { pathname, search } = useLocation();

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    const initialFormStep = currentFormStep === "/";
    // if addressSearch is true then user has come from address search widget on home page.
    const addressSearch = parsedQuery.number || parsedQuery.street;
    // if the user arrives from address search with the same address details then show the dialog.
    const sameAddressSearch =
      parsedQuery.number === selectedAddress?.number &&
      parsedQuery.postcode === selectedAddress?.postcode &&
      parsedQuery.street?.toString().toLowerCase() ===
        selectedAddress?.street.toLowerCase();
    // if creditCardReroute is true then user has been re-routed after successful credit card verification.
    const creditCardReroute = pathname === "/payment" && parsedQuery.result;
    // only show the modal if there is a partial sign up and the user has
    // not come from an address search or been re-routed from credit card verification.
    if (
      (addressSearch && sameAddressSearch) ||
      (!initialFormStep && !addressSearch && !creditCardReroute)
    ) {
      dispatch(setPartialSignUpDialogState(true));
    }
  }, []);

  const handleContinueToJoinClick = useCallback(() => {
    dispatch(setPartialSignUpDialogState(false));
  }, []);

  const handleChangeAddressClick = useCallback(() => {
    resetStorage();
    dispatch(clearState());
    dispatch(setCurrentFormStep("/"));
    dispatch(setPartialSignUpDialogState(false));
  }, []);

  const renderDialogContent = useCallback(() => {
    return (
      <SDialogContent>
        <Typography variant="h3">Continue join to Z?</Typography>
        <Typography variant="body1">
          Kia ora! We have restored your past application for:
        </Typography>
        <Typography variant="body2">
          {formatAddress(selectedAddress)}
        </Typography>
        <Spacer />
        <SDialogActions>
          <SButton
            variant="contained"
            id="submit-re-estimate"
            onClick={handleContinueToJoinClick}
          >
            Continue to join
          </SButton>
          <MLink
            color="textPrimary"
            component="button"
            variant="body1"
            id="button-close"
            onClick={handleChangeAddressClick}
          >
            Check another address
          </MLink>
          <Spacer />
        </SDialogActions>
      </SDialogContent>
    );
  }, [handleContinueToJoinClick, handleChangeAddressClick, selectedAddress]);

  return (
    <Dialog
      open={partialSignUpDialogOpen}
      aria-labelledby="partial-sign-up-dialog"
      fullWidth={true}
      maxWidth="xs"
    >
      {renderDialogContent()}
    </Dialog>
  );
}
