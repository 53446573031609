import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { financiallyRound } from "lib/util";
import React from "react";
import { styled } from "styled-components";
import { COLORS, PADDING } from "ui/theme";

import evAtHomePlanAndSmartChargerCopy from "../../compounds/static/evAtHomePlanAndSmartChargerCopy.json";

const { evSmartChargerPlanPricingCopy } = evAtHomePlanAndSmartChargerCopy;

const SubContainer = styled.div`
  margin-bottom: ${PADDING.medium};
`;

const getAdjustedToExclusiveGSTPrice = (
  value: string,
  GSTInclusive: boolean,
): string => {
  if (!GSTInclusive) {
    return financiallyRound(parseFloat(value) / 1.15, 2);
  }
  return financiallyRound(parseFloat(value), 2);
};

export const EVSmartChargerPricingTable: React.FC<{ GSTInclusive: boolean }> =
  React.memo(({ GSTInclusive }) => {
    return (
      <SubContainer>
        <TableContainer sx={{ marginBottom: `${PADDING.medium}` }}>
          <Table aria-label="rates-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="h5"
                    component="p"
                    sx={{ color: COLORS.charcoal }}
                  >
                    {evSmartChargerPlanPricingCopy.heading}
                  </Typography>
                </TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" component="p">
                    Payment type options
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2" component="p">
                    Fully installed cost
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" component="p">
                    Upfront payment in full
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="caption" component="p">
                    From ${getAdjustedToExclusiveGSTPrice("2195", GSTInclusive)}
                    *
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" component="p">
                    24 month repayment interest free
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="caption" component="p">
                    From $
                    {getAdjustedToExclusiveGSTPrice("91.46", GSTInclusive)} per
                    month*
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell component="th" scope="row" align="right">
                  <Typography variant="caption" component="p">
                    GST {GSTInclusive ? "inclusive" : "exclusive"}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="caption" display="inline">
          {evSmartChargerPlanPricingCopy.tableExtraInfo}
        </Typography>
      </SubContainer>
    );
  });
