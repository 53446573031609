import { ROUTES } from "domain/configuration";
import { clearState, setCurrentFormStep } from "domain/core/reducers";
import { resetStorage } from "lib/util";
import React, { useCallback } from "react";
import { useAppDispatch } from "store/storeHooks";
import { styled } from "styled-components";
import { MLink } from "ui/components/StyledComponents";
import { COLORS } from "ui/theme";

type BackButtonProps = {
  formStep: string;
  onNavigationBack?: () => void;
};

const LeftArrow = styled.i`
  height: 11px;
  width: 11px;
  border: solid ${COLORS.indigo};
  border-width: 0 3px 3px 0;
  border-radius: 0px 1px 2px;
  display: inline-block;
  margin: 2px;
  transform: rotate(135deg);
`;

export const BackButton: React.FC<BackButtonProps> = ({
  formStep,
  onNavigationBack,
}) => {
  const dispatch = useAppDispatch();

  const handleNavigateBack = useCallback(() => {
    if (["/", ROUTES.address].includes(formStep)) {
      resetStorage();
      dispatch(clearState());
    }
    if (onNavigationBack) onNavigationBack();
    dispatch(setCurrentFormStep(formStep));
  }, [formStep, onNavigationBack]);

  return (
    <MLink
      id="link-previous-form-step"
      onClick={handleNavigateBack}
      sx={{ display: "inline-block", marginBottom: "3px" }}
    >
      <LeftArrow />
    </MLink>
  );
};
