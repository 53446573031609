import { HOCDialog } from "../HOC/HOCDialog";
import { BaseDialogProps } from "./BaseDialog";
import {
  EVSmartChargerDialogContent,
  EVTCDialogContent,
  FuelTCDialogContent,
} from "./PlanDialogContent";

const EVTCDialog = HOCDialog(EVTCDialogContent);

const FuelTCDialog = HOCDialog(FuelTCDialogContent);

const EVSmartChargerDialog = HOCDialog<
  BaseDialogProps,
  { GSTInclusive: boolean }
>(EVSmartChargerDialogContent);

export { EVSmartChargerDialog, EVTCDialog, FuelTCDialog };
