import React, { useCallback } from "react";
import { styled } from "styled-components";

const NoStyleA = styled.a`
  text-decoration: none;
`;

export interface ChatLinkProps {
  children: React.ReactNode;
}

export default (props: ChatLinkProps) => {
  const handleOnClick = useCallback(() => {
    // @ts-ignore
    $zopim.livechat.window.show();
  }, []);

  return (
    <NoStyleA onClick={handleOnClick} {...props}>
      {props.children}
    </NoStyleA>
  );
};
