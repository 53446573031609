import axios from "axios";
import { zElectricCreateAccountApi } from "domain/core/api";
import { rollbarConfig } from "lib/rollbarConfig";
import Rollbar from "rollbar";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_Z_API_ENDPOINT,
  headers: { "Content-Type": "application/json" },
});

export const apiFlickClient = axios.create({
  baseURL: process.env.REACT_APP_FLICK_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

const rollbar = new Rollbar(rollbarConfig);

apiFlickClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const requestData = error.response.config.data;
    const errors = Array.isArray(error.response.data.errors)
      ? error.response.data.errors.length > 0
        ? error.response.data.errors
        : ["Undefined error"]
      : JSON.parse(error.response.data.errors) ?? ["Undefined error"];
    const requestError = errors.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (currentError: any) => currentError.title ?? currentError,
    );
    rollbar.error(requestError, error, JSON.stringify(requestData));
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => {
    const requestURL = response.config.url;
    const responseData = response.config.data;
    if (requestURL.includes(zElectricCreateAccountApi)) {
      rollbar.info(
        "Z Sharetank activated",
        JSON.stringify(response.config),
        `reponseStatus: ${response.status}`,
        JSON.stringify(responseData),
      );
    }
    return response;
  },
  (error) => {
    const requestURL = error.response.config.url;
    const requestData = error.response.config.data;
    const errors = Array.isArray(error.response.data.errors)
      ? error.response.data.errors.length > 0
        ? error.response.data.errors
        : ["Undefined error"]
      : JSON.parse(error.response.data.errors) ?? ["Undefined error"];
    const requestError = errors.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (currentError: any) => currentError.title ?? currentError,
    );

    if (requestURL.includes(zElectricCreateAccountApi)) {
      rollbar.error(
        "Z Sharetank activated fail",
        requestError,
        error,
        JSON.stringify(requestData),
      );
    } else {
      rollbar.error(requestError, error, JSON.stringify(requestData));
    }
    return Promise.reject(error);
  },
);
