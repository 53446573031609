import { Typography } from "@mui/material";
import CloseIcon from "assets/icon-close-white.svg";
import { PromoCookie } from "domain/core/types";
import { fetchPromoCookie } from "lib/util";
import React, { useCallback, useState } from "react";
import { styled } from "styled-components";
import { COLORS } from "ui/theme";

const NotificationBanner = styled.div`
  background-color: ${COLORS.tekapoBlue};
  text-align: left;
  width: 100%;
`;

const NotificationContent = styled.div`
  align-items: center;
  display: flex;
  padding: 0 10px;
`;

const Close = styled.button`
  background: none;
  border: none;
  padding: 10px;

  &::after {
    background: url(${CloseIcon}) no-repeat center;
    content: "";
    cursor: pointer;
    display: block;
    height: 12px;
    width: 12px;
  }
`;

const SrOnly = styled.span`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const ReversedTypography = styled(Typography)`
  color: ${COLORS.white};
  flex: 1;
  font-size: 18px;
  letter-spacing: 0.4px;
  padding: 10px 0;
`;

export function Notification() {
  const [showNotification, setShowNotification] = useState<boolean>(true);

  const handleDismiss = useCallback(() => {
    setShowNotification(false);
  }, []);

  function findPromoReward(): number | undefined {
    const promoCookie: PromoCookie = fetchPromoCookie();
    if (promoCookie) {
      const rewardsArray = promoCookie["bundles"][0]["rewards"];
      const promoReward = rewardsArray.find(
        (reward) => reward["type"] === "tenure_reward",
      )?.value;
      return promoReward ? parseInt(promoReward) : undefined;
    }
  }

  const promoValue = findPromoReward();
  return (
    <NotificationBanner>
      {promoValue !== undefined && (
        <NotificationContent
          style={{ display: showNotification ? "flex" : "none" }}
        >
          <ReversedTypography variant="h5">
            For joining today, you'll score a bonus {promoValue}L of fuel after
            you switch in. Ka mau te pai!
          </ReversedTypography>
          <Close onClick={handleDismiss}>
            <SrOnly>Close</SrOnly>
          </Close>
        </NotificationContent>
      )}
    </NotificationBanner>
  );
}
