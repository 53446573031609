/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  determineFormStep,
  fetchPromoCookie,
  formatNumber,
  parseFlickError,
} from "lib/util";
// eslint-disable-next-line import/named
import { SagaIterator } from "redux-saga";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "store/configureStore";

import {
  checkSuccessfulAuthorisationAction,
  completeRegistrationAction,
  createFlickSignUpAction,
  createWindcaveTransactionAction,
  fetchRateCardsAction,
  getFlickSignUpAction,
  setCampaignLitresAction,
  updateFlickSignUpAction,
  validateAddressAction,
  validateICPAction,
  verifyCodeAction,
  verifyMobileAction,
} from "./actions";
import * as api from "./api";
import {
  selectAddress,
  setAddress,
  setCampaigns,
  setCreationSourceIdentifier,
  setCurrentFormStep,
  setEvChargerColor,
  setEvChargerInclusion,
  setFlickSignUpStateComplete,
  setFlickUserId,
  setLeadSource,
  setPartialSignUpDialogState,
  setPromoCode,
  setSelectedProduct,
  setUsageType,
  setUserChecks,
  setUserDetails,
  setUserPayment,
  setUserProperty,
  setUserRewards,
  updateEligibility,
} from "./reducers";
import {
  AddressType,
  EligibilityType,
  GetBundleResponse,
  GetSignUpResponse,
  PromoCookie,
  RateCardResponse,
  SelectAddressPayload,
  SignUpResponse,
  SupplyAddresses,
  UsageType,
  User,
  UserRewards,
  VerificationPayload,
  VerifyCodeResponse,
  VerifyPhoneStartResponse,
  WindcaveAuthorisation,
  WindcaveTransaction,
} from "./types";

// Selector
const getSelectedAddress = (state: RootState) => state.coreData.selectedAddress;
const getSelectedEligibilityType = (state: RootState) =>
  state.coreData.selectedEligibilityType;
const getFlickUserId = (state: RootState) => state.coreData.flickUserId;
const getCreationSource = (state: RootState) => state.coreData.creationSource;
const getSignUpState = (state: RootState) => state.coreData.signUpState;
const getPromoCode = (state: RootState) => state.coreData.promoCode;
const getUser = (state: RootState) => state.coreData.user;
const getLeadSource = (state: RootState) => state.coreData.leadSource;
const getProductMetadata = (state: RootState) => state.coreData.productMetadata;
const getSelectedProduct = (state: RootState) => state.coreData.selectedProduct;
const getUserRewards = (state: RootState) => state.coreData.user.rewards;
const getVerificationId = (state: RootState) => state.coreData.verifyId;
const getTransactionId = (state: RootState) => state.coreData.transactionId;

export default function* CoreSagaWatcher(): SagaIterator {
  yield all([
    takeLatest(validateAddressAction.request.type, validateAddress),
    takeLatest(validateICPAction.request.type, validateICP),
    takeLatest(verifyMobileAction.request.type, verifyMobile),
    takeLatest(verifyCodeAction.request.type, verifyCode),
    takeLatest(
      createWindcaveTransactionAction.request.type,
      createWindcaveTransaction,
    ),
    takeLatest(
      checkSuccessfulAuthorisationAction.request.type,
      checkSuccessfulAuthorisation,
    ),
    takeLatest(createFlickSignUpAction.request.type, createFlickSignUp),
    takeLatest(updateFlickSignUpAction.request.type, updateFlickSignUp),
    takeLatest(getFlickSignUpAction.request.type, getFlickSignUp),
    takeLatest(completeRegistrationAction.request.type, completeRegistration),
    takeLatest(selectAddress.type, fetchRateCards),
    // takeLatest(fetchRateCardsAction.request.type, fetchRateCards),
    takeLatest(setCampaignLitresAction.request.type, setCampaignLitres),
  ]);
}

function* validateAddress(action: PayloadAction<AddressType>): SagaIterator {
  try {
    const response: SupplyAddresses = yield call(
      api.validateAddress,
      action.payload,
    );
    yield put(validateAddressAction.success(response));

    // One result, auto select address with eligibility
    if (response.data.length === 1) {
      const { city, number, street, suburb, postcode, unit_identifier } =
        response.included[0].attributes;
      yield put(
        selectAddress({
          address: {
            city,
            number,
            street,
            suburb,
            icp: response.data[0].attributes.ea_icp_id,
            fullAddress: "",
            postcode: postcode ?? "",
            unit: unit_identifier,
          },
          eligibility: response.data[0].attributes.eligibility,
        }),
      );
    }

    // No results, select address with eligibility unknown
    if (response.data.length === 0) {
      yield put(
        selectAddress({
          address: {
            ...action.payload,
          },
          eligibility: undefined,
        }),
      );
    }
  } catch (err: any) {
    yield put(validateAddressAction.failure(err));
  }
}

function* validateICP(action: PayloadAction<string>): SagaIterator {
  try {
    const response: SupplyAddresses = yield call(
      api.validateICP,
      action.payload,
    );
    yield put(
      validateICPAction.success({
        // @ts-ignore
        eligibleAddresses: response,
      }),
    );

    // No results, select address with eligibility unknown
    // This doesn't run at the moment because Network responds with a CORS error if the ICP doesn't exist
    if (response.data.length === 0) {
      yield put(validateICPAction.failure(new Error("No results")));
      return;
    }

    const { city, number, street, suburb, postcode, unit_identifier } =
      response.included[0].attributes;
    yield put(
      selectAddress({
        address: {
          city,
          number,
          street,
          suburb,
          icp: response.data[0].attributes.ea_icp_id,
          fullAddress: "",
          postcode: postcode ?? "",
          unit: unit_identifier,
        },
        eligibility: response.data[0].attributes.eligibility,
      }),
    );
  } catch (err: any) {
    yield put(validateICPAction.failure(err));
  }
}

function* fetchRateCards(
  action: PayloadAction<SelectAddressPayload>,
): SagaIterator {
  const eligibility: EligibilityType = yield select(getSelectedEligibilityType);
  const address: AddressType = yield select(getSelectedAddress);
  if (
    eligibility === "success" ||
    eligibility === "manual" ||
    eligibility === "export"
  ) {
    yield put(fetchRateCardsAction.request());
    const litreValue = findPromoReward() || 50;
    const campaigns = [
      {
        campaignLitres: litreValue,
      },
    ];
    yield put(setCampaigns(campaigns));

    const icp = action.payload.address.icp || address.icp;
    if (icp) {
      try {
        const response: RateCardResponse = yield call(api.fetchRateCards, icp);
        const rateCardAttributes = response.data.map(
          (rateCard) => rateCard.attributes,
        );
        yield put(fetchRateCardsAction.success(rateCardAttributes));
        yield put(setUsageType("standard"));
      } catch (err: any) {
        yield put(fetchRateCardsAction.failure(err));
        updateEligibility({ eligibility: "manual" });
      }
    } else {
      yield put(fetchRateCardsAction.failure(new Error("ICP not found")));
    }
  }
}

function findPromoReward(): number | undefined {
  const promoCookie: PromoCookie = fetchPromoCookie();

  if (!promoCookie) {
    return undefined;
  }

  const rewardsArray = promoCookie["bundles"][0]["rewards"];
  const promoReward =
    rewardsArray?.length > 0
      ? rewardsArray.find((reward) => reward["type"] === "tenure_reward")?.value
      : undefined;
  return promoReward ? parseInt(promoReward) : undefined;
}

function* verifyMobile(
  action: PayloadAction<VerificationPayload>,
): SagaIterator {
  try {
    const verifyResponse: VerifyPhoneStartResponse = yield call(
      api.verifyPhoneStart,
      {
        countryCode: "NZ", // FIXME: are we supporting non-nz numbers
        number: action.payload.phone,
      },
    );
    yield put(verifyMobileAction.success(verifyResponse));
  } catch (err: any) {
    yield put(verifyMobileAction.failure(err));
  }
}

function* verifyCode(action: PayloadAction<string>): SagaIterator {
  try {
    const id = yield select(getVerificationId);
    const response: VerifyCodeResponse = yield call(api.verifyPhoneCode, {
      code: action.payload,
      id,
    });
    yield put(verifyCodeAction.success(response));
  } catch (err: any) {
    yield put(verifyCodeAction.failure(err));
  }
}

function* createWindcaveTransaction(): SagaIterator {
  try {
    const id = yield select(getFlickUserId);
    const response: WindcaveTransaction = yield call(
      api.createWindcaveTransaction,
      id,
    );
    yield put(createWindcaveTransactionAction.success(response));
    window.open(response.data.attributes.url, "_self");
  } catch (err: any) {
    yield put(createWindcaveTransactionAction.failure(err));
  }
}

function* checkSuccessfulAuthorisation(): SagaIterator {
  const id = yield select(getTransactionId);
  try {
    const response: WindcaveAuthorisation = yield call(
      api.getWindcaveAuthorisation,
      id,
    );
    if (response.data.attributes.result === "APPROVED") {
      yield put(checkSuccessfulAuthorisationAction.success(response));
    } else {
      yield put(
        checkSuccessfulAuthorisationAction.failure(
          new Error("Error - Transaction was not successful"),
        ),
      );
    }
  } catch (err: any) {
    yield put(checkSuccessfulAuthorisationAction.failure(err));
  }
}

function* createFlickSignUp(): SagaIterator {
  const addressDetails: AddressType = yield select(getSelectedAddress);
  const user: User = yield select(getUser);
  const creationSource = yield select(getCreationSource);
  const signUpState = yield select(getSignUpState);
  const promoCode = yield select(getPromoCode);
  const leadSource = yield select(getLeadSource);
  const selectedProduct = yield select(getSelectedProduct);
  const productMetadata = yield select(getProductMetadata);

  try {
    const response: SignUpResponse = yield call(api.createUserFlickSignUp, {
      addressDetails,
      creationSource,
      signUpState: signUpState,
      promoCode,
      leadSource,
      selectedProduct,
      productMetadata,
      property: user.property,
    });
    // explicitly set the sign up cookie to no value.
    document.cookie = `signUpId=;`;
    // then reset it to the id returned from this sign up call.
    document.cookie = `signUpId=${response.data.id};`;
    yield put(createFlickSignUpAction.success(response));
  } catch (err: any) {
    const errorMessage = parseFlickError(err?.response);
    yield put(createFlickSignUpAction.failure(errorMessage));
  }
}

function* updateFlickSignUp(): SagaIterator {
  const addressDetails: AddressType = yield select(getSelectedAddress);
  const user: User = yield select(getUser);
  const id: string = yield select(getFlickUserId);
  const creationSource = yield select(getCreationSource);
  const signUpState = yield select(getSignUpState);
  const promoCode = yield select(getPromoCode);
  const leadSource = yield select(getLeadSource);
  const selectedProduct = yield select(getSelectedProduct);
  const productMetadata = yield select(getProductMetadata);

  try {
    const response: SignUpResponse = yield call(api.updateUserFlickSignUp, {
      id,
      addressDetails,
      details: {
        ...user.details,
        mobileNumber: formatNumber(user.details.mobileNumber),
      },
      payment: user.payment,
      property: user.property,
      checks: user.checks,
      creationSource,
      signUpState: signUpState,
      promoCode,
      leadSource,
      selectedProduct,
      productMetadata,
    });
    yield put(updateFlickSignUpAction.success(response));
  } catch (err: any) {
    const errorMessage = parseFlickError(err?.response);
    yield put(updateFlickSignUpAction.failure(errorMessage));
  }
}

function* setCampaignLitres(): SagaIterator {
  try {
    const promoCode = yield select(getPromoCode);
    const response: GetBundleResponse = yield call(api.getBundle, promoCode);
    const litreValue =
      Number(
        response.included.find((reward) => reward.type === "tenure_reward")
          ?.attributes?.value,
      ) || 50;
    const campaigns = [
      {
        campaignLitres: litreValue,
      },
    ];
    yield put(setCampaigns(campaigns));
    yield put(setCampaignLitresAction.success(response));
  } catch (err: any) {
    const errorMessage = parseFlickError(err?.response);
    yield put(setCampaignLitresAction.failure(errorMessage));
  }
}

function* getFlickSignUp(): SagaIterator {
  const id: string = yield select(getFlickUserId);

  try {
    const response: GetSignUpResponse = yield call(api.getUserFlickSignUp, id);
    const signUpAttributes = response.data.attributes;
    yield put(
      setAddress({
        city: signUpAttributes.city,
        number: signUpAttributes.street_number,
        street: signUpAttributes.street,
        suburb: signUpAttributes.suburb,
        icp: signUpAttributes.icp,
        fullAddress: "",
        postcode: signUpAttributes.postcode,
        unit: signUpAttributes.unit,
      }),
    );
    yield put(
      setUserDetails({
        dob: signUpAttributes.date_of_birth,
        emailAddress: signUpAttributes.email,
        firstName: signUpAttributes.first_name,
        lastName: signUpAttributes.last_name,
        prefName: signUpAttributes.preferred_name,
        mobileNumber: signUpAttributes.primary_phone,
      }),
    );
    if (signUpAttributes.promo_code) {
      yield put(setPromoCode(signUpAttributes.promo_code));
    }
    if (signUpAttributes.lead_source) {
      yield put(setLeadSource(signUpAttributes.lead_source));
    }
    yield put(setCampaignLitresAction.request());
    yield put(
      setUserProperty({
        moveDate: signUpAttributes?.moving_in_date || undefined,
        switchDate: signUpAttributes?.requested_switch_date || undefined,
        usageType: signUpAttributes.usage_type as UsageType,
        dwellingType: signUpAttributes?.dwelling_type,
        medicallyDependent: signUpAttributes?.medical_dependency,
        situation: signUpAttributes.requested_switch_date
          ? "switch_provider"
          : "moved_in",
      }),
    );
    const currentFormStep = determineFormStep(response);
    yield put(setCurrentFormStep(currentFormStep));
    yield put(getFlickSignUpAction.success(response));
  } catch (err: any) {
    const errorMessage = parseFlickError(err?.response);
    yield put(getFlickSignUpAction.failure(errorMessage));
  }
}

function* completeRegistration(): SagaIterator {
  const flickApplicationId = yield select(getFlickUserId);
  const rewards: UserRewards = yield select(getUserRewards);
  const verificationId = yield select(getVerificationId);

  if (flickApplicationId !== null) {
    try {
      // Create Z Electric Account
      yield call(api.createZElectricAccount, {
        flickApplicationId,

        preferredFuelType: rewards.fuelType,
        verificationId,
      });
      yield put(completeRegistrationAction.success(flickApplicationId));
    } catch (err: any) {
      yield put(completeRegistrationAction.failure(err));
    }
  } else {
    yield put(
      completeRegistrationAction.failure(
        new Error("No flick user id - flick sign up failed"),
      ),
    );
  }
}
