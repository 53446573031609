import { Typography } from "@mui/material";
import { ReactComponent as CircularRightArrow } from "assets/circular-arrow-right.svg";
import { AddressType } from "domain/core/types";
import { media } from "lib/styles";
import { formatAddress } from "lib/util";
import React from "react";
import { styled } from "styled-components";
import { NextStepsCard } from "ui/components/NextStepsCard";
import { SmartChargerBenefitsCard } from "ui/components/SmartChargerBenefitsCard";
import {
  CompletionCardContainer,
  ContentWrapper,
  DownArrowWrapper,
  RowLayout,
} from "ui/components/StyledComponents";
import { PADDING } from "ui/theme";

import evAtHomePlanCopy from "../static/evAtHomePlanCopy.json";

const { evAtHomePlanCompletionNextSteps, evAtHomePlanSmartChargerBenefits } =
  evAtHomePlanCopy;

const PaddedContainer = styled.div`
  flex: 1;
  margin-right: ${PADDING.xLarge};
`;

const SRowLayout = styled(RowLayout)`
  flex-basis: 60%;
  padding-top: ${PADDING.large};
  flex-direction: column;
  ${media.md`
    flex-direction: row;
  `}
`;

interface NextStepsProps {
  address: AddressType;
}

export const EvPlanCompletion: React.FC<NextStepsProps> = ({ address }) => {
  return (
    <>
      <Typography variant="h4">Next steps</Typography>
      <ContentWrapper>
        <Typography variant="body1">
          We’ll kick off the process for getting{" "}
          <Typography display="inline" variant="body2">
            {formatAddress(address)}{" "}
          </Typography>
          switched over to Z and will send you an email once things are
          underway. While we do this, these are the next steps within the
          process and what to expect. Thank you for choosing Z.
        </Typography>
      </ContentWrapper>

      <CompletionCardContainer>
        {evAtHomePlanCompletionNextSteps?.map(
          ({
            completionStepNumber,
            completionStepHeading,
            completionStepText,
          }) => (
            <NextStepsCard
              cardStepNumber={completionStepNumber}
              cardHeading={completionStepHeading}
              cardText={completionStepText}
            />
          ),
        )}
      </CompletionCardContainer>

      <Typography
        variant="body1"
        sx={{
          paddingBottom: PADDING.xLarge,
        }}
      >
        If you need to get in touch with our support centre we can identify your
        account by your name and phone number. You can now close this browser
        page.
      </Typography>

      <PaddedContainer>
        <DownArrowWrapper>
          <CircularRightArrow height="50px" width="50px" />
        </DownArrowWrapper>

        <Typography variant="h3">
          Super charge your benefits with a smart charger
        </Typography>

        <Typography variant="body1">
          Our EV plan is designed to help you up your home charging game - to
          ensure you can reap the benefits from your shift to electric. Add an
          Evnex smart charger to further fast-track your savings.
        </Typography>
      </PaddedContainer>

      <SRowLayout>
        {evAtHomePlanSmartChargerBenefits?.map(
          ({ benefitIconName, benefitHeading, benefitText }) => (
            <SmartChargerBenefitsCard
              cardIconName={benefitIconName}
              cardHeading={benefitHeading}
              cardText={benefitText}
            />
          ),
        )}
      </SRowLayout>
    </>
  );
};
