import { Typography } from "@mui/material";
import React from "react";

import { ContentWrapper } from "../StyledComponents";

export const ICPExplanation: React.FC = () => {
  return (
    <ContentWrapper>
      <Typography variant="h5">What’s an ICP number?</Typography> Sorry - we did
      promise that this would be straight forward. Every property has a unique
      Installation Control Point (ICP) number that identifies it on the New
      Zealand electricity network. You can find your ICP on your current power
      bill somewhere under the invoice number. It consists of 15 digits - mostly
      numbers with a few letters at the end.
    </ContentWrapper>
  );
};
