import { CircularProgress, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { styled } from "styled-components";

interface Props {
  loading: boolean;
  message?: string;
  children?: ReactNode | Array<ReactNode>;
}
const Spacer = styled.div`
  height: 2rem;
`;

const Loading = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ loading, ...props }: { loading: boolean; [key: string]: any }) => (
    <div {...props} />
  ),
)`
  ${(p) =>
    p.loading &&
    `opacity: 0.2;
  pointer-events: none;`}
`;

const LoadingMessage = styled.div`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 10rem;
`;

export function LoadingWrapper(props: Props) {
  return (
    <div>
      {props.loading && (
        <LoadingMessage>
          <Typography variant="h4">
            {props.message ? props.message : "Loading"}
          </Typography>
          <Spacer />
          <CircularProgress size="5rem" />
        </LoadingMessage>
      )}
      <Loading loading={props.loading}>{props.children}</Loading>
    </div>
  );
}
