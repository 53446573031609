import { Container, Typography } from "@mui/material";
import { ReactComponent as CircularRightArrow } from "assets/circular-arrow-right.svg";
import { ProductName } from "domain/core/types";
import { sendAnalyticsPageView } from "lib/util";
import React, { useEffect } from "react";
import { persistor } from "store/configureStore";
import { useAppSelector } from "store/storeHooks";
import { DownArrowWrapper } from "ui/components/StyledComponents";
import { EvPlanCompletion } from "ui/compounds/success/EvPlanCompletion";
import { EvPlanWithSmartChargerCompletion } from "ui/compounds/success/EvPlanWithSmartChargerCompletion";
import { FuelRewardsCompletion } from "ui/compounds/success/FuelRewardsCompletion";

export const Success: React.FC = () => {
  window.onbeforeunload = () => null;

  useEffect(() => {
    sendAnalyticsPageView("Great, your application has been submitted");
    // Clear all local storage
    persistor.flush();
    persistor.purge();
  }, []);

  const {
    selectedProduct,
    selectedAddress: address,
    user: { property, details },
    productMetadata,
  } = useAppSelector((store) => store.coreData);

  const switchingProvider = property.situation === "switch_provider";

  return (
    <Container component="main">
      <Typography variant="h3">
        Great, your application has been submitted
      </Typography>
      <DownArrowWrapper>
        <CircularRightArrow height="50px" width="50px" />
      </DownArrowWrapper>
      {selectedProduct === ProductName.Z_ELECTRIC_REWARDS && (
        <>
          <FuelRewardsCompletion
            preferredName={details.prefName}
            selectedProduct={selectedProduct}
            address={address}
            isSwitching={switchingProvider}
          />
        </>
      )}

      {selectedProduct === ProductName.Z_EV &&
        (productMetadata.includeEVcharger === true ? (
          <EvPlanWithSmartChargerCompletion address={address} />
        ) : (
          <EvPlanCompletion address={address} />
        ))}
    </Container>
  );
};
