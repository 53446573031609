export const REQUEST_STATUS = {
  Idle: "Idle",
  Pending: "Pending",
  Failed: "Failed",
  Fulfilled: "Fulfilled",
} as const;

export type RequestStatus = keyof typeof REQUEST_STATUS;

export const DATE_FORMAT = "yyyy-MM-dd";

export const RESET_ACTION = {
  type: "RESET",
};
