import { Link } from "@mui/material";
import { formatAddress } from "lib/util";
import React from "react";
import { styled } from "styled-components";
import { COLORS, PADDING } from "ui/theme";

const AddressHeader = styled.h4`
  color: ${COLORS.charcoal};
  font-size: ${20 / 16}rem;
  font-weight: 400;
  padding-top: ${PADDING.small};
  padding-bottom: ${PADDING.medium};
  margin: 0;
`;

export const AddressResultDisplay = ({ address, onCheckAnother }) => {
  return (
    <>
      <AddressHeader id="text-confirmed-address">
        {formatAddress({ ...address })}
      </AddressHeader>
      <Link
        component="button"
        id="button-check-another"
        onClick={onCheckAnother}
      >
        Check another address
      </Link>
    </>
  );
};
