import { CheckCircle } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import { styled } from "styled-components";
import Button from "ui/components/Button";
import { RowLayoutLeft } from "ui/components/StyledComponents";
import { PADDING } from "ui/theme";

const CheckIcon = styled(CheckCircle)`
  margin-right: 0.5rem;
  font-size: 2rem;
`;

interface Props {
  onCreditCardPress(): void;
  cardAdded: boolean;
  error?: string;
  billCycle: string;
}

export default function CreditCardInput(props: Props) {
  if (props.cardAdded) {
    return (
      <RowLayoutLeft>
        <CheckIcon color="secondary" />
        <Typography variant="body1"> Card succesfully added </Typography>
      </RowLayoutLeft>
    );
  }

  return (
    <>
      <Button
        id="button-add-card"
        variant="contained"
        onClick={props.onCreditCardPress}
        buttonsize="large"
        sx={{ marginTop: PADDING.small, marginBottom: PADDING.small }}
      >
        Add Credit or Debit card
      </Button>
      <Typography variant="caption" component="p">
        Your card details will be securely recorded and used to pay your{" "}
        {props.billCycle} bill. Your card will not be charged at this point in
        the application process.
      </Typography>
      <Typography variant="caption" color="error">
        {props.error}
      </Typography>
    </>
  );
}
