/* eslint-disable @typescript-eslint/no-shadow */
import { Collapse, Link, TextField, Typography } from "@mui/material";
import { AddressType } from "domain/core/types";
import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import AddressFinder, {
  AddressMeta,
} from "ui/components/AddressFinder/AddressFinder";
import Button from "ui/components/Button";
import { ICPExplanation } from "ui/components/ICPExplanation/ICPExplanation";
import {
  RowLayout,
  RowLayoutLeft,
  RowLayoutRight,
  TextInput,
} from "ui/components/StyledComponents";
import theme, { PADDING } from "ui/theme";

export type ADDRESS_INPUT_TYPE =
  | "address_finder"
  | "manual_address"
  | "manual_icp";

interface State {
  street: string;
  number: string;
  suburb: string;
  city: string;
  postcode: string;
  fullAddress: string;
  unit: string;
  icp: string;
  error: string;
  icpError: string;
}

interface Props {
  onConfirmAddress(address: AddressType): void;
  onConfirmICP(icp: string): void;
  loading: boolean;
  error?: string;
  defaultEntry: ADDRESS_INPUT_TYPE;
}

const TextInputLeft = styled(TextField)({
  marginRight: PADDING.xSmall,
  width: `calc(50% - ${PADDING.xSmall})`,
  [theme.breakpoints.up("xs")]: {
    width: "5rem",
  },
});

const TextInputRight = styled(TextField)({
  marginLeft: PADDING.xSmall,
  width: `calc(50% - ${PADDING.xSmall})`,
  [theme.breakpoints.up("xs")]: {
    width: "7rem",
  },
});

const PostcodeInput = styled(TextField)({
  width: "7rem",
  marginLeft: PADDING.small,
  [theme.breakpoints.up("xs")]: {
    width: "8rem",
  },
});

export default function AddressInputForm(props: Props) {
  const [inputType, setInputType] = useState<ADDRESS_INPUT_TYPE>(
    props.defaultEntry,
  );
  const [state, setState] = useState<State>({
    street: "",
    number: "",
    suburb: "",
    city: "",
    postcode: "",
    fullAddress: "",
    unit: "",
    icp: "",
    error: "",
    icpError: "",
  });

  useEffect(() => {
    setInputType(props.defaultEntry);
  }, [props.defaultEntry]);

  const handleShowManual = (event: React.MouseEvent) => {
    event.preventDefault();
    setInputType("manual_address");
  };

  const handleShowICP = (event: React.MouseEvent) => {
    event.preventDefault();
    setInputType("manual_icp");
  };

  const handleShowFinder = (event: React.MouseEvent) => {
    event.preventDefault();
    setInputType("address_finder");
  };

  const handleOnManualNext = useCallback(
    //@ts-ignore
    (e) => {
      e.preventDefault();
      const { city, fullAddress, suburb, street, number, unit, postcode } =
        state;

      setState((state) => ({ ...state, error: "" }));
      if (street.length < 3) {
        setState((state: State) => ({
          ...state,
          error: "Please enter your address",
        }));
        return;
      }

      props.onConfirmAddress({
        city,
        fullAddress,
        suburb,
        street,
        number,
        unit,
        postcode,
      });
    },
    [props, state],
  );

  const handleOnICPNext = useCallback(
    //@ts-ignore
    (e) => {
      e.preventDefault();
      setState((state) => ({ ...state, error: "" }));
      if (state.icp === "") {
        setState((state: State) => ({
          ...state,
          icpError: "Please enter your ICP",
        }));
        return;
      }
      props.onConfirmICP(state.icp);
    },
    [props, state.icp],
  );

  const handleUnitChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setState((state) => ({ ...state, unit: value }));
    },
    [],
  );

  const handleStreetChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setState((state) => ({ ...state, street: value, error: "" }));
    },
    [],
  );
  const handleNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setState((state) => ({ ...state, number: value, error: "" }));
    },
    [],
  );
  const handleSuburbChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setState((state) => ({ ...state, suburb: value }));
    },
    [],
  );
  const handleCityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setState((state) => ({ ...state, city: value }));
    },
    [],
  );
  const handlePostcodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setState((state) => ({ ...state, postcode: value }));
    },
    [],
  );
  const handleICPChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setState((state) => ({ ...state, icp: value, icpError: "" }));
    },
    [],
  );

  const handleSelection = (_address: string, metaAddress: AddressMeta) => {
    const unit = metaAddress?.unit_identifier ?? "";
    const number = `${metaAddress?.number ?? ""}${metaAddress?.alpha ?? ""}`;
    const fullAddress = metaAddress.a ?? "";

    setState((state: State) => ({
      ...state,
      street: metaAddress.street ?? "",
      number,
      fullAddress,
      unit,
      suburb: metaAddress.suburb ?? "",
      city: metaAddress.city ?? "",
      postcode: metaAddress.postcode ?? "",
    }));

    props.onConfirmAddress({
      fullAddress,
      unit,
      street: metaAddress.street ?? "",
      number,
      suburb: metaAddress.suburb ?? "",
      city: metaAddress.city ?? "",
      postcode: metaAddress.postcode ?? "",
      region: metaAddress.region,
    });
  };

  return (
    <>
      <Collapse in={inputType === "address_finder"}>
        <Typography variant="body1">
          We have created power plans that suit both your car and home.
        </Typography>
        <Typography variant="body1">
          It will only take a couple of minutes to check your connection and get
          a personalised price. Don't worry, any personal information you
          provide through this process will be handled by Z in accordance with
          <Link
            href={`${process.env.REACT_APP_Z_WEBSITE_URL}privacy/#privacy-policy`}
            target="_blank"
            variant="caption"
            sx={{
              marginLeft: PADDING.xSmall,
            }}
          >
            Z's Privacy Policy.
          </Link>
        </Typography>
        <RowLayout>
          <AddressFinder onSelected={handleSelection} />
        </RowLayout>
        <Typography variant="body1">
          Can't find your address? Enter{" "}
          <Link
            component="button"
            onClick={handleShowManual}
            id="button-show-manual"
          >
            your address
          </Link>{" "}
          manually or enter{" "}
          <Link component="button" onClick={handleShowICP} id="button-show-icp">
            your ICP number
          </Link>{" "}
          manually.
        </Typography>
      </Collapse>
      <Collapse in={inputType === "manual_address"} mountOnEnter unmountOnExit>
        <form onSubmit={handleOnManualNext} autoComplete={"true"}>
          <Typography variant="body1">
            Sorry that we couldn't find your address automatically! Feel free to
            type it in below though.
          </Typography>
          <RowLayout>
            <TextInputLeft
              id="unit"
              label="Unit"
              inputProps={{ maxLength: 6 }}
              value={state.unit}
              onChange={handleUnitChange}
            />
            <TextInputRight
              id="street-number"
              label="Number"
              inputProps={{ maxLength: 6 }}
              value={state.number}
              onChange={handleNumberChange}
            />
            <TextField
              id="street"
              label="Street address *"
              inputProps={{ maxLength: 255 }}
              value={state.street}
              onChange={handleStreetChange}
              error={!!state.error}
              helperText={!state.error ? "" : state.error}
              sx={{
                width: "100%",
                [theme.breakpoints.up("xs")]: {
                  flex: 1,
                  marginLeft: PADDING.small,
                },
              }}
            />
          </RowLayout>
          <RowLayout>
            <TextInput
              id="address-line-2"
              label="Suburb"
              inputProps={{ maxLength: 255 }}
              value={state.suburb}
              onChange={handleSuburbChange}
            />
          </RowLayout>
          <RowLayout>
            <TextInput
              id="address-level2"
              label="Town or City"
              value={state.city}
              inputProps={{ maxLength: 255 }}
              onChange={handleCityChange}
            />
            <PostcodeInput
              id="postal-code"
              label="Postcode"
              value={state.postcode}
              inputProps={{ maxLength: 4 }}
              onChange={handlePostcodeChange}
            />
          </RowLayout>
          <RowLayout>
            <RowLayoutLeft>
              <Button
                variant="contained"
                type="submit"
                id="button-manual-address-next"
              >
                Confirm address
              </Button>
            </RowLayoutLeft>
            <RowLayoutRight>
              <Link
                component="button"
                onClick={handleShowFinder}
                id="button-show-address-finder"
                sx={{ marginTop: PADDING.medium }}
              >
                Use address finder
              </Link>
            </RowLayoutRight>
          </RowLayout>
        </form>
      </Collapse>
      <Collapse in={inputType === "manual_icp"} mountOnEnter unmountOnExit>
        <form onSubmit={handleOnICPNext} autoComplete={"true"}>
          <RowLayoutLeft>
            <Typography variant="body1">
              Do you have your ICP number handy?
            </Typography>
          </RowLayoutLeft>
          <Typography variant="body1">
            {!props.error
              ? "It can be found on your meter or on a previous bill - it's 15 digits long."
              : props.error}
          </Typography>
          <RowLayout>
            <TextInput
              id="icp-number"
              label="ICP"
              placeholder="e.g. 0000123456ABCD1"
              inputProps={{ maxLength: 15 }}
              value={state.icp}
              onChange={handleICPChange}
              error={!!state.icpError}
            />
          </RowLayout>
          <ICPExplanation />
          <RowLayout>
            <RowLayoutLeft>
              <Button variant="contained" type="submit" id="button-icp-next">
                Find my address
              </Button>
            </RowLayoutLeft>
            <RowLayoutRight>
              <Link
                component="button"
                onClick={handleShowManual}
                id="button-show-manual-from-icp"
                sx={{ marginTop: PADDING.medium }}
              >
                Enter address manually
              </Link>
            </RowLayoutRight>
          </RowLayout>
        </form>
      </Collapse>
    </>
  );
}
