import { AccordionDetails } from "@mui/material";
import { RateCard, Rates } from "domain/core/types";
import React from "react";
import { AdditionalMeters } from "ui/compounds/AdditionalMeters";
import { CurrentCharges } from "ui/compounds/CurrentCharges";

{
  /* This component and places it is renderd is to be uncommented for future April Price change work */
}
// const AprilPriceChangeCopy = () => (
//   <Typography variant="body1" component="p" sx={{ paddingTop: PADDING.large }}>
//     Prices will be changing from 1 April 2025, in line with annual price
//     adjustments.
//   </Typography>
// );

const EVPlanAdditionalMetersAccordionDetails: React.FC<{
  sortedOtherRates: Rates[];
  GSTInclusive: boolean;
}> = ({ sortedOtherRates, GSTInclusive }) => (
  <AccordionDetails>
    <AdditionalMeters
      sortedOtherRates={sortedOtherRates}
      GSTInclusive={GSTInclusive}
    />
  </AccordionDetails>
);

const EVPlanYourPricesAccordionDetails: React.FC<{
  sortedBaseRates: Rates[];
  exportRates: Rates[];
  rateCard: RateCard;
  GSTInclusive: boolean;
}> = ({ sortedBaseRates, exportRates, rateCard, GSTInclusive }) => (
  <AccordionDetails>
    <CurrentCharges
      sortedBaseRates={sortedBaseRates}
      exportRates={exportRates}
      rateCard={rateCard}
      GSTInclusive={GSTInclusive}
    />
    {/* <AprilPriceChangeCopy />  */}
  </AccordionDetails>
);

const FuelPlanAdditionalMetersAccordionDetails: React.FC<{
  sortedOtherRates: Rates[];
  GSTInclusive: boolean;
}> = ({ sortedOtherRates, GSTInclusive }) => (
  <AccordionDetails>
    <AdditionalMeters
      sortedOtherRates={sortedOtherRates}
      GSTInclusive={GSTInclusive}
    />
  </AccordionDetails>
);

const FuelPlanYourPricesAccordionDetails: React.FC<{
  sortedBaseRates: Rates[];
  exportRates: Rates[];
  rateCard: RateCard;
  GSTInclusive: boolean;
}> = ({ sortedBaseRates, exportRates, rateCard, GSTInclusive }) => (
  <AccordionDetails>
    <CurrentCharges
      sortedBaseRates={sortedBaseRates}
      exportRates={exportRates}
      rateCard={rateCard}
      GSTInclusive={GSTInclusive}
    />
    {/* <AprilPriceChangeCopy /> */}
  </AccordionDetails>
);

export {
  EVPlanAdditionalMetersAccordionDetails,
  EVPlanYourPricesAccordionDetails,
  FuelPlanAdditionalMetersAccordionDetails,
  FuelPlanYourPricesAccordionDetails,
};
