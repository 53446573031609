import { Close as CloseIcon } from "@mui/icons-material";
import { Container, Dialog, DialogTitle, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { PropsWithChildren, useCallback } from "react";

export interface BaseDialogProps extends PropsWithChildren {
  visible: boolean;
  onClose: () => void;
  title: string;
}

export const BaseDialog: React.FC<BaseDialogProps> = ({
  visible,
  onClose,
  title,
  children,
}) => {
  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Container>
      <Dialog
        onClose={handleCloseModal}
        aria-label={`View the ${title}`}
        open={visible}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <>
            <Typography variant="h4">{title}</Typography>
            {onClose && (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </>
        </DialogTitle>
        {children}
      </Dialog>
    </Container>
  );
};
