import { ButtonBase, Typography } from "@mui/material";
import { ReactComponent as AndroidLogo } from "assets/android-play-store.svg";
import { ReactComponent as CircularRightArrow } from "assets/circular-arrow-right.svg";
import { ReactComponent as IOSLogo } from "assets/ios-app-store.svg";
import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import {
  AppContainer,
  ArrowIconWrapper,
  CompletionImage,
  CompletionRow,
  DownArrowWrapper,
} from "ui/components/StyledComponents";

import Button from "../Button";

const SButtonBase = styled(ButtonBase)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

interface CompletionExtraInfoProps {
  header: string;
  paragraphs: string[];
  link: string;
  image: string;
}

const handleIOSPress = (event: React.MouseEvent) => {
  event.preventDefault();
  window.location.href =
    "https://apps.apple.com/nz/app/z-coffee-card/id594734342?ls=1";
};

const handleAndroidPress = (event: React.MouseEvent) => {
  event.preventDefault();
  window.location.href =
    "https://play.google.com/store/apps/details?id=nz.co.zenergy.loyaltycard.android";
};

export const CompletionExtraInfo: React.FC<CompletionExtraInfoProps> = ({
  header,
  paragraphs,
  link,
  image,
}) => {
  return (
    <CompletionRow>
      <AppContainer>
        <DownArrowWrapper>
          <CircularRightArrow height="50px" width="50px" />
        </DownArrowWrapper>

        <Typography variant="h3" color="secondary">
          {header}
        </Typography>

        {paragraphs.map((paragraph, index) => (
          <Typography key={`paragraph-${index}`}>{paragraph}</Typography>
        ))}

        {link === "AppDownload" ? (
          <div>
            <SButtonBase onClick={handleIOSPress} id="button-ios-store">
              <IOSLogo width={200} height={50} preserveAspectRatio="xMinYMin" />
            </SButtonBase>
            <SButtonBase onClick={handleAndroidPress} id="button-android-store">
              <AndroidLogo
                width={200}
                height={50}
                preserveAspectRatio="xMinYMin"
              />
            </SButtonBase>
          </div>
        ) : (
          <Button
            variant="contained"
            id="button-confirmed-next"
            component={Link}
            sx={{
              width: "fit-content",
              marginBottom: "15px",
            }}
            to={`${process.env.REACT_APP_Z_WEBSITE_URL}${link}`}
            endIcon={
              <ArrowIconWrapper>
                <CircularRightArrow />
              </ArrowIconWrapper>
            }
            target="_blank"
          >
            Learn more
          </Button>
        )}
      </AppContainer>

      <CompletionImage
        srcSet={image}
        src={image}
        alt={image === "FuelRewardsComplete" ? "Sharetank" : "EV charger"}
      />
    </CompletionRow>
  );
};
