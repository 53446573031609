/* eslint-disable @typescript-eslint/no-shadow */
import { InputAdornment, TextField, Typography } from "@mui/material";
import {
  DatePicker as MUIDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { addYears, compareAsc, compareDesc, format } from "date-fns";
import { verifyCodeAction, verifyMobileAction } from "domain/core/actions";
import { setUserDetails, setUserRewards } from "domain/core/reducers";
import { FuelTypes, ProductName } from "domain/core/types";
import { useModal, usePrevious } from "lib/hooks";
import { DATE_FORMAT } from "lib/types";
import { isValidNZNumber, trimNZMobileNumber } from "lib/util";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { styled } from "styled-components";
import Button from "ui/components/Button";
import { SingleSelectRadio } from "ui/components/SingleSelectRadio";
import { FormWrapper, RowLayoutLeft } from "ui/components/StyledComponents";

import VerificationDialog from "./VerificationDialog";

const MIN_BIRTHDATE = new Date(1900, 0, 1);
const MAX_BIRTHDATE = addYears(new Date(), -18);

const DatePicker = styled(MUIDatePicker)`
  display: flex;
  flex: 1;
  margin-top: 4px;
  margin-bottom: 8px;
  min-width: 10rem;
`;

interface Props {
  onUserVerified(): void;
}

interface State {
  firstName: string;
  lastName: string;
  prefName: string;
  mobile: string;
  email: string;
  confEmail: string;
  date: Date | null;
  fuelType?: FuelTypes;

  errFirstName?: string;
  errLastName?: string;
  errMobile?: string;
  errEmail?: string;
  errConfEmail?: string;
  errDate?: string;
  errVerifyCode?: string;
  errPrefName?: string;
  errFuelType?: string;
}

const FUEL_TYPE_OPTIONS: Array<{
  label: string;
  value: FuelTypes;
  isVisible: boolean;
}> = [
  { label: "Z91 Unleaded", value: "regular", isVisible: true },
  { label: "ZX95 Premium", value: "premium", isVisible: true },
  { label: "Z Diesel", value: "diesel", isVisible: true },
];

const renderMobileHelperText = (
  errorText: string,
  selectedProduct: string,
  includeEvCharger: boolean,
) => {
  if (errorText) {
    return errorText;
  }
  if (selectedProduct === ProductName.Z_EV && includeEvCharger) {
    return "We'll use this number to arrange your charger installation.";
  }
  if (selectedProduct === ProductName.Z_ELECTRIC_REWARDS) {
    return "The mobile number you will, or have used with Z App. You'll need to verify this number in order to start collecting fuel rewards in Sharetank.";
  }
  return "";
};

export default function UserDetailsForm(props: Props) {
  const dispatch = useAppDispatch();

  const {
    productMetadata,
    selectedProduct,
    user: { details, rewards },
    verifyCodeRequestStatus,
    verificationRequestStatus,
  } = useAppSelector((store) => store.coreData);
  const [isModalVisible, openModal, closeModal] = useModal();
  const prevStatus = usePrevious(verifyCodeRequestStatus);
  const prevVerifyStatus = usePrevious(verificationRequestStatus);

  useEffect(() => {
    if (
      prevVerifyStatus === "Pending" &&
      verificationRequestStatus === "Failed"
    ) {
      closeModal();
      setState((state) => ({
        ...state,
        errMobile: "Failed to verify number, please try again.",
      }));
    }

    if (prevStatus === "Pending" && verifyCodeRequestStatus === "Fulfilled") {
      closeModal();
      props.onUserVerified();
    }

    if (prevStatus === "Pending" && verifyCodeRequestStatus === "Failed") {
      setState((state) => ({
        ...state,
        errVerifyCode: "Incorrect code, please try again.",
      }));
    }
  }, [
    verifyCodeRequestStatus,
    verificationRequestStatus,
    prevStatus,
    closeModal,
    props,
    prevVerifyStatus,
  ]);

  const [state, setState] = useState<State>({
    firstName: details.firstName,
    lastName: details.lastName,
    prefName: details.prefName,
    email: details.emailAddress,
    confEmail: details.emailAddress,
    mobile: details.mobileNumber,
    date: details.dob && new Date(details.dob),
    fuelType: rewards.fuelType,
  });

  const validateInputs = (): boolean => {
    const errors = {
      errFirstName: "",
      errLastName: "",
      errMobile: "",
      errEmail: "",
      errConfEmail: "",
      errDate: "",
      errVerifyCode: "",
      errPrefName: "",
      errFuelType: "",
    };
    let noErrors = true;

    if (state.firstName === "") {
      noErrors = false;
      errors.errFirstName = "Please enter your name";
    }
    if (state.lastName === "") {
      noErrors = false;
      errors.errLastName = "Please enter your name";
    }
    if (state.prefName === "") {
      noErrors = false;
      errors.errPrefName = "Please enter your preferred name";
    }
    if (
      state.date === null ||
      isNaN(state.date.getTime()) ||
      compareAsc(state.date, MIN_BIRTHDATE) === -1 ||
      compareDesc(state.date, MAX_BIRTHDATE) === -1
    ) {
      noErrors = false;
      errors.errDate =
        "Please enter a valid birthdate, must be 18 years of age";
    }
    if (state.email === "") {
      noErrors = false;
      errors.errEmail = "Please enter your email";
    }
    if (state.confEmail !== state.email) {
      noErrors = false;
      errors.errConfEmail = "Oops, your email addresses don't match";
    }
    if (state.mobile === "") {
      noErrors = false;
      errors.errMobile = "Please enter your number";
    }
    if (!isValidNZNumber(state.mobile)) {
      noErrors = false;
      errors.errMobile = "Please enter a valid number";
    }
    if (
      selectedProduct === ProductName.Z_ELECTRIC_REWARDS &&
      state.fuelType === undefined
    ) {
      noErrors = false;
      errors.errFuelType = "Oops, please select one.";
    }

    if (noErrors) {
      return true;
    }

    setState((state: State) => ({
      ...state,
      ...errors,
    }));
    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnVerify = (e?: any) => {
    e?.preventDefault();

    setState((state) => ({ ...state, errVerifyCode: "" }));
    const trimmedMobile = trimNZMobileNumber(state.mobile);

    if (validateInputs() && state.date !== null) {
      dispatch(
        setUserDetails({
          dob: format(state.date, DATE_FORMAT),
          emailAddress: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          prefName: state.prefName,
          mobileNumber: trimmedMobile,
        }),
      );
      dispatch(setUserRewards({ fuelType: state.fuelType }));
      dispatch(
        verifyMobileAction.request({ email: state.email, phone: state.mobile }),
      );
      openModal();
    }
  };

  // onSubmit handler when selected product is z_ev - continue to next step in join form without having to verify mobile.
  const handleOnContinue = (e?: any) => {
    e?.preventDefault();

    setState((state) => ({ ...state, errVerifyCode: "" }));
    const trimmedMobile = trimNZMobileNumber(state.mobile);

    if (validateInputs() && state.date) {
      dispatch(
        setUserDetails({
          dob: format(state.date, DATE_FORMAT),
          emailAddress: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          prefName: state.prefName,
          mobileNumber: trimmedMobile,
        }),
      );
      props.onUserVerified();
    }
  };

  const handleConfirmCodePress = (code: string) => {
    dispatch(verifyCodeAction.request(code));
  };

  const handleDateChange = useCallback((date: Date) => {
    setState((state) => ({ ...state, date, errDate: "" }));
  }, []);

  const handleFirstNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const firstName = event.target.value;
      setState((state) => ({ ...state, firstName, errFirstName: "" }));
    },
    [],
  );

  const handleFirstNameOnBlur = useCallback(() => {
    if (state.prefName === "") {
      setState((state) => ({
        ...state,
        prefName: state.firstName,
        errPrefName: "",
      }));
    }
  }, [state.prefName]);

  const handleLastNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const lastName = event.target.value;
      setState((state) => ({ ...state, lastName, errLastName: "" }));
    },
    [],
  );

  const handlePrefNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const prefName = event.target.value;
      setState((state) => ({ ...state, prefName, errPrefName: "" }));
    },
    [],
  );

  const handleMobileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const mobile = event.target.value.replace(/\s/g, "");
      setState((state) => ({ ...state, mobile, errMobile: "" }));
    },
    [],
  );

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const email = event.target.value;
      setState((state) => ({ ...state, email, errEmail: "" }));
    },
    [],
  );
  const handleConfEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const confEmail = event.target.value;
      setState((state) => ({ ...state, confEmail, errConfEmail: "" }));
    },
    [],
  );

  const handleFuelTypeChange = useCallback((fuelType: FuelTypes) => {
    setState((state) => ({ ...state, fuelType, errFuelType: undefined }));
  }, []);

  const loading =
    verificationRequestStatus === "Pending" ||
    verifyCodeRequestStatus === "Pending";

  return (
    <>
      <VerificationDialog
        loading={loading}
        error={state.errVerifyCode}
        onConfirmPress={handleConfirmCodePress}
        onClose={closeModal}
        onResendPress={handleOnVerify}
        visible={isModalVisible}
        verificationMethod={{
          type: "mobile",
          value: details.mobileNumber,
        }}
      />
      <Typography variant="h4">About you</Typography>
      <FormWrapper
        onSubmit={
          selectedProduct === ProductName.Z_ELECTRIC_REWARDS
            ? handleOnVerify
            : handleOnContinue
        }
      >
        <TextField
          id="first-name"
          label="First name"
          inputProps={{ maxLength: 255 }}
          value={state.firstName}
          onBlur={handleFirstNameOnBlur}
          onChange={handleFirstNameChange}
          error={!!state.errFirstName}
          helperText={!state.errFirstName ? undefined : state.errFirstName}
        />
        <TextField
          label="Last name"
          inputProps={{ maxLength: 255 }}
          value={state.lastName}
          onChange={handleLastNameChange}
          error={!!state.errLastName}
          helperText={!state.errLastName ? undefined : state.errLastName}
        />
        <TextField
          id="preferred-name"
          label="Preferred name (optional)"
          value={state.prefName}
          inputProps={{ maxLength: 255 }}
          onChange={handlePrefNameChange}
          error={!!state.errPrefName}
          helperText={
            !state.errPrefName
              ? "This is how we'll greet you."
              : state.errPrefName
          }
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date of birth"
            format="dd/MM/yyyy"
            minDate={MIN_BIRTHDATE}
            maxDate={MAX_BIRTHDATE}
            openTo="year"
            views={["year", "month", "day"]}
            value={state.date}
            onChange={handleDateChange}
            slotProps={{
              textField: {
                error: !!state.errDate,
                helperText: !state.errDate
                  ? "We need this for credit checking purposes."
                  : state.errDate,
                inputProps: { margin: "normal", id: "date-of-birth" },
              },
            }}
          />
        </LocalizationProvider>

        {selectedProduct === ProductName.Z_ELECTRIC_REWARDS && (
          <>
            <Typography variant="h4">
              Select the type of fuel you want deposited into your Sharetank
            </Typography>
            <Typography variant="caption" component="p">
              Your fuel rewards are earned in Sharetank - a virtual fuel tank,
              as part of the Z App.
            </Typography>
            <RowLayoutLeft>
              <SingleSelectRadio
                options={FUEL_TYPE_OPTIONS}
                onChangeOption={handleFuelTypeChange}
                selectedOptionValue={rewards.fuelType}
              />
            </RowLayoutLeft>
            {state.errFuelType && (
              <Typography color="error">{state.errFuelType}</Typography>
            )}{" "}
          </>
        )}

        <Typography variant="h4">Contact details</Typography>

        {selectedProduct === ProductName.Z_EV && (
          <RowLayoutLeft>
            <TextField
              id="tel-mobile-number"
              label="Mobile number (NZ)"
              value={state.mobile}
              onChange={handleMobileChange}
              error={!!state.errMobile}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+64</InputAdornment>
                ),
              }}
              helperText={renderMobileHelperText(
                state.errMobile,
                selectedProduct,
                productMetadata.includeEVcharger,
              )}
            />
          </RowLayoutLeft>
        )}

        <TextField
          id="email"
          label="Email address"
          type="email"
          inputProps={{ maxLength: 255 }}
          value={state.email}
          onChange={handleEmailChange}
          error={!!state.errEmail}
          helperText={
            !state.errEmail
              ? "We may use your email address to get in touch to help with your application."
              : state.errEmail
          }
        />
        <TextField
          id="confirm-email"
          label="Confirm email address"
          type="email"
          inputProps={{ maxLength: 255 }}
          value={state.confEmail}
          onChange={handleConfEmailChange}
          error={!!state.errConfEmail}
          helperText={!state.errConfEmail ? undefined : state.errConfEmail}
        />

        {selectedProduct === ProductName.Z_ELECTRIC_REWARDS && (
          <RowLayoutLeft>
            <TextField
              id="tel-mobile-number"
              label="Mobile number (NZ)"
              value={state.mobile}
              onChange={handleMobileChange}
              error={!!state.errMobile}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+64</InputAdornment>
                ),
              }}
              helperText={renderMobileHelperText(
                state.errMobile,
                selectedProduct,
                productMetadata.includeEVcharger,
              )}
            />
          </RowLayoutLeft>
        )}

        {selectedProduct === ProductName.Z_ELECTRIC_REWARDS ? (
          <Button
            variant="contained"
            type="submit"
            id="button-verify-user-details"
          >
            Verify my mobile
          </Button>
        ) : (
          <Button
            variant="contained"
            type="submit"
            id="button-continue-user-details"
          >
            Continue
          </Button>
        )}
      </FormWrapper>
    </>
  );
}
