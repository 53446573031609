import { ExpandMore } from "@mui/icons-material";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  useMediaQuery,
} from "@mui/material";
import React, { PropsWithChildren } from "react";
import theme from "ui/theme";

export interface BaseAccordionProps extends PropsWithChildren {
  planName: string;
  heading: React.ReactNode;
}

export const BaseAccordion: React.FC<BaseAccordionProps> = ({
  planName,
  heading,
  children,
}) => {
  const isMediumScreenSize = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <MuiAccordion disableGutters={true} defaultExpanded={isMediumScreenSize}>
      <MuiAccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${planName}-${heading}-accordion-content`}
        id={`${planName}-${heading}-accordion`}
      >
        {heading}
      </MuiAccordionSummary>
      {children}
    </MuiAccordion>
  );
};
