import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { styled } from "styled-components";
import { PADDING } from "ui/theme";

const RadioWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: ${PADDING.small};
`;

interface SingleSelectRadioProps {
  options: {
    value: string;
    isVisible: boolean;
    label: string;
    subLabel?: string;
    collapse?: ReactNode;
  }[];
  onChangeOption: (value: string) => void;
  selectedOptionValue?: string;
}

export const SingleSelectRadio: React.FC<SingleSelectRadioProps> = ({
  onChangeOption,
  options,
  selectedOptionValue,
}) => {
  return (
    <RadioGroup
      aria-labelledby="radio-buttons-group-options-label"
      name="radio-buttons-group"
      defaultValue={selectedOptionValue ?? null}
    >
      {options.map(
        (option, index) =>
          option.isVisible && (
            <RadioWrapper key={`radio-control-label-${index}`}>
              <FormControlLabel
                key={`radio-control-label-${index}`}
                value={option.value}
                control={<Radio />}
                label={option.label}
                onChange={(event) =>
                  onChangeOption((event.target as HTMLInputElement).value)
                }
              />
              {option.subLabel && (
                <Typography
                  variant="body1"
                  sx={{ marginLeft: PADDING.xLarge, marginTop: `-0.75rem` }}
                >
                  {option.subLabel}
                </Typography>
              )}
              {option.collapse ? option.collapse : null}
            </RadioWrapper>
          ),
      )}
    </RadioGroup>
  );
};
