import { createSlice } from "@reduxjs/toolkit";
import { REQUEST_STATUS } from "lib/types";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/index.js";

import {
  checkSuccessfulAuthorisationAction,
  completeRegistrationAction,
  createFlickSignUpAction,
  createWindcaveTransactionAction,
  fetchRateCardsAction,
  getFlickSignUpAction,
  setCampaignLitresAction,
  updateFlickSignUpAction,
  validateAddressAction,
  validateICPAction,
  verifyCodeAction,
  verifyMobileAction,
} from "./actions";
import { CoreState, UsageType } from "./types";

const INITIAL_STATE: CoreState = {
  validateAddressRequestStatus: REQUEST_STATUS.Idle,
  verificationRequestStatus: REQUEST_STATUS.Idle,
  verifyCodeRequestStatus: REQUEST_STATUS.Idle,
  createFlickSignUpRequestStatus: REQUEST_STATUS.Idle,
  updateFlickSignUpRequestStatus: REQUEST_STATUS.Idle,
  getFlickSignUpRequestStatus: REQUEST_STATUS.Idle,
  setCampaignLitresStatus: REQUEST_STATUS.Idle,
  completeRegistrationRequestStatus: REQUEST_STATUS.Idle,
  windcaveTransactionRequestStatus: REQUEST_STATUS.Idle,
  windcaveAuthorisationRequestStatus: REQUEST_STATUS.Idle,
  rateCardsRequestStatus: REQUEST_STATUS.Idle,
  eligibleAddresses: {
    data: [],
    included: [],
  },
  userId: null,
  flickUserId: null,
  user: {
    checks: {
      marketingAccepted: false,
      smsSwitchNotificationsAccepted: false,
      termsAccepted: false,
    },
    details: {
      dob: null,
      emailAddress: "",
      firstName: "",
      lastName: "",
      mobileNumber: "",
      prefName: "",
    },
    rewards: {
      fuelType: undefined,
    },
    payment: {
      paymentType: "credit_card",
      creditCheckAccecpted: false,
    },
    property: {
      usageType: UsageType.STANDARD,
      medicallyDependent: undefined,
      dwellingType: undefined,
      situation: undefined,
    },
  },
  campaigns: [],
  signUpState: "0",
  currentFormStep: "/",
  partialSignUpDialogOpen: false,
  promoCode: undefined,
  leadSource: undefined,
  selectedProduct: undefined,
  selectedAddress: undefined,
  productMetadata: {
    includeEVcharger: false,
    evChargerColor: undefined,
  },
  rateCards: undefined,
};

const persistConfig: PersistConfig<CoreState> = {
  storage,
  key: `@z-electric:core`,
  version: 2,
  blacklist: [
    "siteRequestStatus",
    "validateAddressRequestStatus",
    "verificationRequestStatus",
    "createFlickSignUpRequestStatus",
    "updateFlickSignUpRequestStatus",
    "getFlickSignUpRequestStatus",
    "verifyCodeRequestStatus",
    "windcaveTransactionRequestStatus",
    "windcaveAuthorisationRequestStatus",
    "completeRegistrationRequestStatus",
    "windcaveSignUpId",
  ],
};

// Generate actions and reducers using createSlice
const coreStateSlice = createSlice({
  name: "coreState",
  initialState: INITIAL_STATE,
  reducers: {
    setUserChecks: (state, action) => {
      state.user.checks = action.payload;
    },
    setUserDetails: (state, action) => {
      state.user.details = action.payload;
    },
    setUserPayment: (state, action) => {
      state.user.payment = action.payload;
    },
    setUserProperty: (state, action) => {
      state.user.property = action.payload;
    },
    setUserRewards: (state, action) => {
      state.user.rewards = action.payload;
    },
    setUsageType: (state, action) => {
      state.user.property.usageType = action.payload;
    },
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setCurrentFormStep: (state, action) => {
      state.currentFormStep = action.payload;
    },
    setPartialSignUpDialogState: (state, action) => {
      state.partialSignUpDialogOpen = action.payload;
    },
    setPromoCode: (state, action) => {
      state.promoCode = action.payload;
      state.flickAccountError = undefined;
    },
    setLeadSource: (state, action) => {
      state.leadSource = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setEvChargerInclusion: (state, action) => {
      state.productMetadata.includeEVcharger = action.payload;
    },
    setEvChargerColor: (state, action) => {
      state.productMetadata.evChargerColor = action.payload;
    },
    setFlickUserId: (state, action) => {
      state.flickUserId = action.payload;
    },
    selectAddress: (state, action) => {
      state.validateAddressRequestStatus = REQUEST_STATUS.Fulfilled;
      state.selectedAddress = action.payload.address;
      state.selectedEligibilityType = action.payload.eligibility;
    },
    setAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    updateEligibility: (state, action) => {
      state.rateCardsRequestStatus = REQUEST_STATUS.Fulfilled;
      state.selectedEligibilityType = action.payload.eligibility;
    },
    setFlickSignUpStateComplete: (state) => {
      state.signUpState = "complete";
    },
    setCreationSourceIdentifier: (state) => {
      state.creationSource = "sign_up_api_guided";
    },
    clearState: (state) => {
      // set state as Initial state
      Object.keys(INITIAL_STATE).forEach((key) => {
        state[key] = INITIAL_STATE[key];
      });
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchRateCardsAction.request, (state) => {
        state.rateCardsRequestStatus = REQUEST_STATUS.Pending;
        state.rateCards = undefined;
      })
      .addCase(fetchRateCardsAction.success, (state, action) => {
        state.rateCardsRequestStatus = REQUEST_STATUS.Fulfilled;
        state.rateCards = action.payload;
      })
      .addCase(fetchRateCardsAction.failure, (state) => {
        state.rateCardsRequestStatus = REQUEST_STATUS.Failed;
        state.rateCards = undefined;
      })
      .addCase(validateAddressAction.request, (state) => {
        state.validateAddressRequestStatus = REQUEST_STATUS.Pending;
        state.selectedAddress = undefined;
      })
      .addCase(validateAddressAction.success, (state, action) => {
        state.validateAddressRequestStatus = REQUEST_STATUS.Fulfilled;
        state.eligibleAddresses = action.payload;
      })
      .addCase(validateAddressAction.failure, (state) => {
        state.validateAddressRequestStatus = REQUEST_STATUS.Failed;
      })
      .addCase(validateICPAction.request, (state) => {
        state.validateAddressRequestStatus = REQUEST_STATUS.Pending;
        state.selectedAddress = undefined;
      })
      .addCase(validateICPAction.success, (state, action) => {
        state.validateAddressRequestStatus = REQUEST_STATUS.Fulfilled;
        state.eligibleAddresses = action.payload;
      })
      .addCase(validateICPAction.failure, (state) => {
        state.validateAddressRequestStatus = REQUEST_STATUS.Failed;
      })
      .addCase(verifyMobileAction.request, (state) => {
        state.verificationRequestStatus = REQUEST_STATUS.Pending;
        state.verifyId = undefined;
      })
      .addCase(verifyMobileAction.success, (state, action) => {
        state.verificationRequestStatus = REQUEST_STATUS.Fulfilled;
        state.verifyId = action.payload.id;
      })
      .addCase(verifyMobileAction.failure, (state) => {
        state.verificationRequestStatus = REQUEST_STATUS.Failed;
        state.verifyId = undefined;
      })
      .addCase(verifyCodeAction.request, (state) => {
        state.verifyCodeRequestStatus = REQUEST_STATUS.Pending;
        state.userId = undefined;
      })
      .addCase(verifyCodeAction.success, (state, action) => {
        state.verifyCodeRequestStatus = REQUEST_STATUS.Fulfilled;
        state.userId = action.payload.userId;
      })
      .addCase(verifyCodeAction.failure, (state) => {
        state.verifyCodeRequestStatus = REQUEST_STATUS.Failed;
        state.userId = undefined;
      })
      .addCase(createWindcaveTransactionAction.request, (state) => {
        state.windcaveTransactionRequestStatus = REQUEST_STATUS.Pending;
      })
      .addCase(createWindcaveTransactionAction.success, (state, action) => {
        state.windcaveTransactionRequestStatus = REQUEST_STATUS.Fulfilled;
        state.windcaveSignUpId = action.payload.data.attributes.sign_up_id;
        state.transactionId = action.payload.data.id;
      })
      .addCase(createWindcaveTransactionAction.failure, (state) => {
        state.windcaveTransactionRequestStatus = REQUEST_STATUS.Failed;
      })
      .addCase(checkSuccessfulAuthorisationAction.request, (state) => {
        state.windcaveAuthorisationRequestStatus = REQUEST_STATUS.Pending;
      })
      .addCase(checkSuccessfulAuthorisationAction.success, (state, action) => {
        state.windcaveAuthorisationRequestStatus = REQUEST_STATUS.Fulfilled;
        state.windcaveSignUpId = action.payload.data.attributes.sign_up_id;
      })
      .addCase(checkSuccessfulAuthorisationAction.failure, (state) => {
        state.windcaveAuthorisationRequestStatus = REQUEST_STATUS.Failed;
      })
      .addCase(createFlickSignUpAction.request, (state) => {
        state.createFlickSignUpRequestStatus = REQUEST_STATUS.Pending;
        state.flickUserId = null;
      })
      .addCase(createFlickSignUpAction.success, (state, action) => {
        state.createFlickSignUpRequestStatus = REQUEST_STATUS.Fulfilled;
        state.flickUserId = action.payload.data.id;
      })
      .addCase(createFlickSignUpAction.failure, (state, action) => {
        state.createFlickSignUpRequestStatus = REQUEST_STATUS.Failed;
        state.flickAccountError = action.payload;
      })
      .addCase(updateFlickSignUpAction.request, (state) => {
        state.updateFlickSignUpRequestStatus = REQUEST_STATUS.Pending;
        state.flickAccountError = undefined;
      })
      .addCase(updateFlickSignUpAction.success, (state) => {
        state.updateFlickSignUpRequestStatus = REQUEST_STATUS.Fulfilled;
      })
      .addCase(updateFlickSignUpAction.failure, (state, action) => {
        state.updateFlickSignUpRequestStatus = REQUEST_STATUS.Failed;
        state.flickAccountError = action.payload;
      })
      .addCase(getFlickSignUpAction.request, (state) => {
        state.getFlickSignUpRequestStatus = REQUEST_STATUS.Pending;
        state.flickAccountError = undefined;
      })
      .addCase(getFlickSignUpAction.success, (state) => {
        state.getFlickSignUpRequestStatus = REQUEST_STATUS.Fulfilled;
        state.flickAccountError = undefined;
      })
      .addCase(getFlickSignUpAction.failure, (state, action) => {
        state.getFlickSignUpRequestStatus = REQUEST_STATUS.Failed;
        state.flickAccountError = action.payload;
      })
      .addCase(completeRegistrationAction.request, (state) => {
        state.completeRegistrationRequestStatus = REQUEST_STATUS.Pending;
      })
      .addCase(completeRegistrationAction.success, (state) => {
        state.completeRegistrationRequestStatus = REQUEST_STATUS.Fulfilled;
      })
      .addCase(completeRegistrationAction.failure, (state) => {
        state.completeRegistrationRequestStatus = REQUEST_STATUS.Failed;
      })
      .addCase(setCampaignLitresAction.request, (state) => {
        state.setCampaignLitresStatus = REQUEST_STATUS.Pending;
      })
      .addCase(setCampaignLitresAction.success, (state) => {
        state.setCampaignLitresStatus = REQUEST_STATUS.Fulfilled;
      })
      .addCase(setCampaignLitresAction.failure, (state) => {
        state.setCampaignLitresStatus = REQUEST_STATUS.Failed;
      }),
});

export const {
  setUserChecks,
  setUserDetails,
  setUserPayment,
  setUserProperty,
  setUserRewards,
  setUsageType,
  setCampaigns,
  setCurrentFormStep,
  setPartialSignUpDialogState,
  setPromoCode,
  setLeadSource,
  setSelectedProduct,
  setEvChargerInclusion,
  setEvChargerColor,
  setFlickUserId,
  setAddress,
  selectAddress,
  updateEligibility,
  setFlickSignUpStateComplete,
  setCreationSourceIdentifier,
  clearState,
} = coreStateSlice.actions;

export const coreReducer = persistReducer(
  persistConfig,
  coreStateSlice.reducer,
);
