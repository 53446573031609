import { Typography } from "@mui/material";
import EvChargerComplete from "assets/ev-charger-complete.png";
import { AddressType } from "domain/core/types";
import { formatAddress } from "lib/util";
import React from "react";
import { CompletionExtraInfo } from "ui/components/CompletionExtraInfo";
import { NextStepsCard } from "ui/components/NextStepsCard";
import {
  CompletionCardContainer,
  ContentWrapper,
} from "ui/components/StyledComponents";

import evAtHomePlanAndSmartChargerCopy from "../static/evAtHomePlanAndSmartChargerCopy.json";

const {
  evAtHomePlanAndSmartChargerCompletionNextSteps,
  evAtHomePlanAndSmartCompletionChargerExtraInfo,
} = evAtHomePlanAndSmartChargerCopy;

const {
  completionExtraInfoHeading,
  completionExtraInfoParagraphs,
  completionExtraInfoLink,
} = evAtHomePlanAndSmartCompletionChargerExtraInfo;

interface EvPlanWithSmartChargerCompletionProps {
  address: AddressType;
}

export const EvPlanWithSmartChargerCompletion: React.FC<
  EvPlanWithSmartChargerCompletionProps
> = ({ address }) => {
  return (
    <>
      <Typography variant="h4">Next steps</Typography>
      <ContentWrapper>
        <Typography variant="body1">
          We’ll kick off the process for getting{" "}
          <Typography display="inline" variant="body2">
            {formatAddress(address)}{" "}
          </Typography>
          switched over to Z and will send you an email once things are
          underway. While we do this, these are the next steps within the
          process and what to expect. Thank you for choosing Z.
        </Typography>
      </ContentWrapper>

      <CompletionCardContainer>
        {evAtHomePlanAndSmartChargerCompletionNextSteps?.map(
          ({
            completionStepNumber,
            completionStepHeading,
            completionStepText,
          }) => (
            <NextStepsCard
              cardStepNumber={completionStepNumber}
              cardHeading={completionStepHeading}
              cardText={completionStepText}
            />
          ),
        )}
      </CompletionCardContainer>
      <ContentWrapper>
        <Typography variant="body1">
          If you need to get in touch with our support centre we can identify
          your account by your name and phone number. You can now close this
          browser page.
        </Typography>
      </ContentWrapper>

      <CompletionExtraInfo
        header={completionExtraInfoHeading}
        paragraphs={completionExtraInfoParagraphs}
        link={completionExtraInfoLink}
        image={EvChargerComplete}
      />
    </>
  );
};
