import { Typography } from "@mui/material";
import { ReactComponent as RightArrow } from "assets/arrow-right-white.svg";
import { ReactComponent as EVHomeIcon } from "assets/icon-ev-home.svg";
import { ReactComponent as EVMoneyIcon } from "assets/icon-ev-money.svg";
import { ReactComponent as EVVehicleIcon } from "assets/icon-ev-vehicle.svg";
import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import Button from "ui/components/Button";
import { Card } from "ui/components/StyledComponents";
import { COLORS, PADDING } from "ui/theme";

const SCard = styled(Card)`
  display: flex;
  flex-basis: calc(50% - ${PADDING.medium});
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${PADDING.large};
  margin-right: 0;
  min-width: 5rem;
  background: ${COLORS.mist};

  & a {
    border-radius: 50%;
    min-width: 3rem;
    padding: 0;
    width: 3rem;
  }
`;

type SmartChargerBenefitsCardProps = {
  cardIconName: string;
  cardHeading: string;
  cardText: string;
};

const cardIcon = (cardIconName) => {
  if (cardIconName === "EvHomeIcon") {
    return <EVHomeIcon />;
  }
  if (cardIconName === "EVMoneyIcon") {
    return <EVMoneyIcon />;
  }
  if (cardIconName === "EVVehicleIcon") {
    return <EVVehicleIcon />;
  }
  return <></>;
};

const smartChargerEvAtHomeLink = `${process.env.REACT_APP_Z_WEBSITE_URL}products-and-services/power-your-home/ev-at-home-plan/`;

export const SmartChargerBenefitsCard: React.FC<
  SmartChargerBenefitsCardProps
> = ({ cardIconName, cardHeading, cardText }) => {
  return (
    <SCard>
      {cardIcon(cardIconName)}
      <Typography variant="h4">{cardHeading}</Typography>
      <Typography variant="body1">{cardText}</Typography>
      <Button
        variant="contained"
        component={Link}
        to={smartChargerEvAtHomeLink}
        target="_blank"
        id="button-link-home-safety"
      >
        <RightArrow />
      </Button>
    </SCard>
  );
};
