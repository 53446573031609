import { useCallback, useEffect, useRef, useState } from "react";

export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const useModal = (): [boolean, () => void, () => void] => {
  const [isVisible, setIsVisible] = useState(false);

  const open = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);

  const close = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  return [isVisible, open, close];
};

export const useViewportWidth = () => {
  const [vw, setVw] = useState(window.innerWidth);

  useEffect(() => {
    const setWidth = () => setVw(window.innerWidth);
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, []);

  return vw;
};
