import { Help } from "@mui/icons-material";
import { Link, TextField, Typography } from "@mui/material";
import { media } from "lib/styles";
import React from "react";
import { styled } from "styled-components";
import tw from "twin.macro";
import theme, { COLORS, PADDING } from "ui/theme";

export const AppContainer = styled.div({
  display: "flex",
  flexFlow: "column wrap",
  justifyContent: "center",
  maxWidth: "100%",
  [theme.breakpoints.up("md")]: {
    maxWidth: "45%",
  },
});

export const ContentWrapper = styled.section({
  maxWidth: "1000px",
});

export const CompletionCardContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
`;

export const CompletionImage = styled.img({
  objectFit: "cover",
  maxWidth: "100%",
  height: "auto",
  marginBottom: "1rem",
  borderRadius: "10px",
  [theme.breakpoints.up("md")]: {
    maxWidth: "45%",
  },
});

export const CompletionNextStepsCard = styled.li({
  backgroundColor: COLORS.backgroundIndigo,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  marginBottom: "1rem",
  padding: "0.5rem 1rem 1rem",
  boxShadow: "0px 1px 4px rgb(0 0 0 / 25%)",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    margin: "1rem",
    padding: "1rem 2rem 2rem",
    width: "calc(50% - 2rem)",
  },
});

export const CompletionNextStepsDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompletionNextStepsDigit = styled.div({
  display: "flex",
  flexDirection: "row",
  flexBasis: "auto",
  marginRight: "1rem",
  [theme.breakpoints.up("md")]: {
    marginRight: "2rem",
  },
});

export const ArrowIconWrapper = styled("svg")`
  display: inherit;
  height: 3rem;
  width: 3rem;
  margin-left: 14px;
  margin-right: -30px;
  margin-top: 13px;
`;

export const DownArrowWrapper = styled("div")`
  margin-top: ${PADDING.small};
  height: 3rem;
  width: 3rem;
  border-radius: 100px;
  box-shadow: 4px 1px 8px rgb(0 0 0 / 25%);
  transform: rotate(90deg);
  ${media.md`
    display: none;
  `}
`;

export const CompletionRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: ${PADDING.large};
`;

export const RowLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const RowLayoutLeft = styled.div`
  ${tw`flex flex-row`}
`;

export const RowLayoutRight = styled.div`
  ${tw`flex flex-row justify-end flex-wrap`}
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

export const TextInput = styled(TextField)`
  flex: 1;
`;

//TODO replace this with <Link>
export const MLink = styled((props) => <Link {...props} />)`
  text-decoration: underline;
  font-weight: 600;
  vertical-align: baseline;

  &:hover {
    text-decoration: none;
    color: ${COLORS.charcoal};
  }
`;

//TODO clean these up and remove unused ones

export const FormSection = styled.div`
  ${tw`bg-white p-4 pt-0 mt-4 md:px-24 shadow rounded-md`}
`;

export const HeaderContainer = styled.div`
  ${tw`w-screen h-24 p-10 md:hidden md:p-0`}
`;

export const Card = styled.div`
  ${tw`shadow-md rounded-2xl py-4 px-8 inline-block mr-4`}
`;
export const ControlledCard = styled.div`
  ${tw`rounded-2xl`}
  width: 100%;
  max-width: 400px;
  padding: ${PADDING.xSmall} ${PADDING.xLarge} ${PADDING.xLarge};
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  margin: ${PADDING.medium} 0;
  ${media.md`
    margin-right: ${PADDING.medium};
    flex: 1;
    width: auto;
  `}
`;

export const ControlledCardSm = styled.div`
  ${tw`rounded-2xl p-4 inline-block mr-4`}
  max-width: 203px;
  min-width: 100px;
  margin-bottom: ${PADDING.medium};
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
`;

export const ProgressContainer = styled.div`
  ${tw` w-screen h-auto md:w-1/3 items-center content-center justify-center p-10`}
`;

export const CapitalisedTypography = styled(Typography)`
  text-transform: capitalize;
`;

export const HeroTypography = styled(Typography)`
  font-size: 2.5rem;
  color: ${COLORS.indigo};
  font-weight: 600;
  line-height: 1;
`;

export const ReveresedTypography = styled(Typography)`
  color: white;
`;

export const SHelpIcon = styled(Help)`
  margin-left: 0.5rem;
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 0.5rem;
  color: ${COLORS.charcoal};

  &:hover {
    color: ${COLORS.indigo};
  }
`;

export const LinkTypography = styled(Typography)`
  border-bottom: 2px solid ${COLORS.tekapoBlue};
  cursor: pointer;

  &:hover {
    color: ${COLORS.tekapoBlue};
  }
`;
