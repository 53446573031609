import { Card, CardContent, Link, Typography } from "@mui/material";
import { ReactComponent as CircleRightArrow } from "assets/circular-arrow-right.svg";
import React, { PropsWithChildren } from "react";
import { styled } from "styled-components";
import Button from "ui/components/Button";
import theme, { COLORS, PADDING } from "ui/theme";

import evAtHomePlanCopy from "../../compounds/static/evAtHomePlanCopy.json";
import { CheckedList } from "../Lists";
import { ArrowIconWrapper } from "../StyledComponents";

const { evPlanName } = evAtHomePlanCopy;

const PlanCardHeader = styled.div`
  background: linear-gradient(90deg, #ed560e 0%, #f89c15 95.55%);
  color: ${COLORS.white};
  display: flex;
  flex-direction: row;
  padding: 17px 20px;
  height: 112px;
`;

const PlanCardHeaderText = styled.h4({
  fontWeight: 700,
  lineHeight: "1.2",
  color: COLORS.white,
  margin: "0",
  paddingTop: "0",
  paddingBottom: PADDING.small,
  paddingLeft: "7px",
  maxWidth: "200px",
  fontSize: `${24 / 16}rem`,
  [theme.breakpoints.up("md")]: {
    maxWidth: "200px",
    fontSize: `${25 / 16}rem`,
  },
  [theme.breakpoints.up("xl")]: {
    paddingTop: PADDING.small,
    maxWidth: "300px",
    fontSize: `${28 / 16}rem`,
  },
});

const IconContainer = styled.div`
  align-content: end;
  margin-left: auto;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`;

export interface PlanCardProps extends PropsWithChildren {
  planName: string;
  shortPlanName: string;
  planIcon: any;
  contentHeading: string;
  joinNowRoute: string;
  onJoinNowClicked?: any;
  exportChannel: boolean;
  controlledChannel: boolean;
  planProofPoints: {
    primaryProofPoint: { text: string; exportOnly: boolean };
    secondaryProofPoints?: { text: string; exportOnly: boolean }[];
  }[];
  onTAndCDialogOpen: () => void;
  onSmartChargerDialogOpen?: () => void;
}

export const PlanCard: React.FC<PlanCardProps> = ({
  planName,
  shortPlanName,
  planIcon,
  contentHeading,
  joinNowRoute,
  onJoinNowClicked,
  exportChannel,
  controlledChannel,
  planProofPoints,
  onTAndCDialogOpen,
  onSmartChargerDialogOpen,
  children,
}) => {
  const activePlanProofPoints: {
    primaryProofPoint: string;
    secondaryProofPoints?: string[];
  }[] = planProofPoints.reduce((acc, proofPoint) => {
    const primary = proofPoint.primaryProofPoint;
    const includePrimary =
      !primary.exportOnly || (exportChannel && primary.exportOnly);

    const secondaryProofPoints = (proofPoint.secondaryProofPoints || [])
      .filter((sp) => !sp.exportOnly || (exportChannel && sp.exportOnly))
      .map((sp) => sp.text);

    if (includePrimary) {
      acc.push({
        primaryProofPoint: primary.text,
        secondaryProofPoints,
      });
    }

    return acc;
  }, []);

  return (
    <Card sx={{ maxHeight: "min-content" }}>
      <PlanCardHeader>
        <PlanCardHeaderText>{planName}</PlanCardHeaderText>
        <IconContainer>{planIcon}</IconContainer>
      </PlanCardHeader>
      <CardContent sx={{ padding: "16px 25px" }}>
        <Typography variant="h4" sx={{ fontSize: "1.375rem", paddingTop: "0" }}>
          {contentHeading}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "1.375rem",
            padding: `${PADDING.medium} 0`,
          }}
        >
          What's available
        </Typography>
        <CheckedList listItems={activePlanProofPoints} />
        {shortPlanName == evPlanName && controlledChannel && (
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            *Free hours and half price rates at night are not applicable to
            separately priced controlled meters.
          </Typography>
        )}
        <LinksContainer>
          <Link sx={{ margin: "0 0 6px 0" }} onClick={onTAndCDialogOpen}>
            *{shortPlanName} and terms
          </Link>
          {planName === "EV at home plan and smart charger" && (
            <Link
              sx={{ marginBottom: "6px" }}
              onClick={onSmartChargerDialogOpen}
            >
              *Smart charger terms and eligibility
            </Link>
          )}
          <Button
            onClick={onJoinNowClicked}
            variant="contained"
            id="button-confirmed-next"
            component={Button}
            to={joinNowRoute}
            endIcon={
              <ArrowIconWrapper>
                <CircleRightArrow />
              </ArrowIconWrapper>
            }
          >
            Join now
          </Button>
        </LinksContainer>
        {children}
      </CardContent>
    </Card>
  );
};
