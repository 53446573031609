import { createTheme } from "@mui/material/styles";
import CalibreRegular from "assets/fonts/Calibre.woff2";
import CalibreBlack from "assets/fonts/Calibre-Black.woff2";
import CalibreBold from "assets/fonts/Calibre-Bold.woff2";

export const COLORS = {
  // New/checked colours
  orange: "#ED560E",
  yellow: "#FFCB08",
  white: "rgba(255,255,255)",
  indigo: "#1E196A",
  lightIndigo: "#3129AC",
  backgroundIndigo: "#EAE9F1",
  charcoal: "#353535",
  lightGrey: "#D5D4DC",
  mist: "#F8F8F8",
  lightBlue: "rgb(31, 25, 106, 0.7)",
  grey: "#D9D9D9",
  gradientOrange: "linear-gradient(90deg, #ed560e 0%, #f89c15 95.55%);",
  gradientIndigo: "linear-gradient(261.59deg, #3129AC -6.6%, #1E196A 79.03%)",
  // Old  colours (to sort/remove)
  black: "#353535",
  blueGrey: "rgba(231,235,237)",
  midCharcoal: "#a7a9ac",
  tekapoBlue: "#00bdce",
  roseRed: "#dc004e",
  green: "#4caf50",
  backgroundBlue: "rgb(241,244,248)",
};

export const PADDING = {
  xSmall: "0.25rem",
  small: "0.5rem",
  medium: "1rem",
  large: "1.5rem",
  xLarge: "2rem",
  xxLarge: "2.75rem",
};

export const maxWidth = "1440px";

const defaultHeading = {
  fontWeight: 700,
  lineHeight: "1.2",
};

const defaultTheme = createTheme();

const theme = createTheme({
  typography: {
    fontFamily: '"Calibre", Helvetica Neue, Arial, sans-serif',
    h1: {
      ...defaultHeading,
      fontSize: `${40 / 16}rem`,
      color: COLORS.white,
      paddingTop: PADDING.large,
      paddingBottom: PADDING.medium,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: `${54 / 16}rem`,
      },
      [defaultTheme.breakpoints.up("lg")]: {
        fontSize: `${74 / 16}rem`,
      },
    },
    h2: {
      ...defaultHeading,
      fontSize: `${38 / 16}rem`,
      color: COLORS.orange,
      paddingTop: PADDING.large,
      paddingBottom: PADDING.medium,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: `${48 / 16}rem`,
      },
      [defaultTheme.breakpoints.up("lg")]: {
        fontSize: `${64 / 16}rem`,
      },
    },
    h3: {
      ...defaultHeading,
      fontSize: `${35 / 16}rem`,
      color: COLORS.orange,
      paddingTop: PADDING.large,
      paddingBottom: PADDING.small,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: `${42 / 16}rem`,
      },
      [defaultTheme.breakpoints.up("lg")]: {
        fontSize: `${52 / 16}rem`,
      },
    },
    h4: {
      ...defaultHeading,
      fontSize: `${24 / 16}rem`,
      color: COLORS.indigo,
      paddingTop: PADDING.large,
      paddingBottom: PADDING.small,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: `${25 / 16}rem`,
      },
      [defaultTheme.breakpoints.up("lg")]: {
        fontSize: `${26 / 16}rem`,
      },
    },
    h5: {
      ...defaultHeading,
      fontSize: `${22 / 16}rem`,
      defaultHeading,
      color: COLORS.indigo,
      paddingTop: PADDING.medium,
      paddingBottom: PADDING.small,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.5",
      color: COLORS.charcoal,
      padding: `${PADDING.small} 0`,
      "& .MuiFormControlLabel-label": {
        padding: 0,
      },
    },
    body2: {
      fontSize: "1rem",
      lineHeight: "1.5",
      fontWeight: 600,
      padding: `${PADDING.small} 0`,
    },
    caption: {
      fontSize: "1rem",
      color: COLORS.charcoal,
      padding: `${PADDING.xSmall} 0`,
    },
    // TODO: clarify below as we go
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: 1,
      margin: "1rem 0",
    },
    subtitle2: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: 1,
      color: COLORS.charcoal,
    },
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      xs: 450,
    },
  },
  // TODO understand how these are applied and update accordingly
  palette: {
    primary: {
      main: COLORS.indigo,
      contrastText: COLORS.white,
    },
    secondary: {
      main: COLORS.orange,
    },
    text: {
      primary: COLORS.charcoal,
      secondary: COLORS.indigo,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "underline",
          fontWeight: "600",
          verticalAlign: "baseline",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "none",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "min-content",
          borderRadius: "16px",
          margin: "5px 5px",
          fill: "#FFF",
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          boxShadow: "none",
          [defaultTheme.breakpoints.up("md")]: {
            margin: "0 5px",
            maxWidth: "404px",
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          background: `linear-gradient(90deg, #ED560E 0%, #F89C15 95.55%)`,
          color: `${COLORS.white}`,
          display: "flex",
          flexDirection: "row-reverse",
        },
        content: {
          display: "flex",
          flexDirection: "row-reverse",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        disableGutters: true,
        root: {
          padding: `${20 / 16}rem`,
          [defaultTheme.breakpoints.up("lg")]: {
            padding: `${20 / 16}rem ${88 / 16}rem`,
            maxWidth: "1440px",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${COLORS.charcoal} !important`,
          padding: "0 0.5rem",
          "&.Mui-checked": {
            color: `${COLORS.orange} !important`,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: `${COLORS.charcoal} !important`,
          "&.Mui-checked": {
            color: `${COLORS.orange} !important`,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `@font-face {
        font-family: "Calibre";
        font-style: "normal";
        font-display: "swap";
        src: url(${CalibreRegular}) format('woff2');
      };
      @font-face {
        font-family: "Calibre";
        font-style: "normal";
        font-display: "swap";
        font-weight: 600;
        src: url(${CalibreBold}) format('woff2');
      };
      @font-face {
        font-family: "Calibre";
        font-style: "normal";
        font-display: "swap";
        font-weight: 900;
        src: url(${CalibreBlack}) format('woff2');
      };
      `,
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& > fieldset": {
            borderColor: COLORS.grey,
            borderRadius: "12px",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: "0.75rem",
          marginBottom: "0.75rem",
          "&.MuiTextField-root": {
            marginTop: "1rem",
            marginBottom: "1rem",
          },
          "> .MuiFormControl-root": {
            // ocassionally they wrap another one in and the margin doubles
            marginBottom: "0",
            marginTop: "0",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginTop: PADDING.xSmall,
          marginBottom: PADDING.xSmall,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          fontWeight: 600,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.white,
          boxShadow: "none",
          "&::before &::after ": {
            margin: "5px 0",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color: COLORS.indigo,
          fontWeight: "600",
          fontSize: `22px`,
          textWrap: "nowrap",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          alignItems: "start",
          display: "flex",
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          display: "inline-block",
          marginTop: "10px",
          minWidth: "45px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          margin: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25);",
          padding: "20px",
          color: COLORS.charcoal,
          fontSize: `${16 / 16}rem`,
          width: "fit-content",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
  },
});

export default theme;
