import { RateCard, Rates, UsageType } from "domain/core/types";

/**
 * @param rateCards an array of rates for different plans
 * @param productName name of the product ( ev or fuel at home)
 * @returns an array of rates for the product
 */
export const getPlanRateCards = (
  rateCards: RateCard[],
  productName: string,
  usageType: UsageType | "standard" | "low",
): RateCard[] => {
  if (!rateCards || !Array.isArray(rateCards) || !rateCards.length) return [];
  return rateCards.filter(
    (rateCard) =>
      rateCard.product_code === productName &&
      rateCard.usage_type === usageType,
  );
};

/**
 * for every meters
 * even the channel name might be different but if the rates are the same then we only display the rates once
 */
export const sortUsageRatesPlan = (usageRates: Rates[]) => {
  const baseRates = [];
  const otherRates = [];
  const exportRates = [];
  const embeddedGenerationChannelContentCode = "EG";
  const uncontrolledChannelContentCodes = [
    "UN",
    "IN",
    "N",
    "D",
    "N|D",
    "DIN",
    "NIN",
    "PK",
    "SH",
    "OP",
  ];

  if (!usageRates || usageRates.length === 0)
    return {
      baseRates: [],
      exportRates: [],
      otherRates: [],
    };

  usageRates.map((usageRate) => {
    usageRate.channels.map((channel) => {
      // Base rates are uncontrolled rates
      if (uncontrolledChannelContentCodes.includes(channel.content_code)) {
        const newChannelRate = {
          unit_code: usageRate.unit_code,
          channel,
          rates: usageRate.rates,
        };
        // Check if baseRates already contains new rate
        const exists = baseRates.some((eachRate) =>
          compareJSONArrays(eachRate.rates, newChannelRate.rates),
        );

        // If new rate doesn't already exist in baseRates, add it
        if (!exists) {
          baseRates.push(newChannelRate);
        }
      } else {
        if (channel.content_code === embeddedGenerationChannelContentCode) {
          const newChannelRate = {
            unit_code: usageRate.unit_code,
            channel,
            rates: usageRate.rates,
          };
          // Check if baseRates already contains new rate
          const exists = exportRates.some((eachRate) =>
            compareJSONArrays(eachRate.rates, newChannelRate.rates),
          );

          // If new rate doesn't already exist in baseRates, add it
          if (!exists) {
            exportRates.push(newChannelRate);
          }
        } else {
          // If it is not uncontrolled or embedded generation, it must be controlled.
          const newChannelRate = {
            unit_code: usageRate.unit_code,
            channel,
            rates: usageRate.rates,
          };
          otherRates.push(newChannelRate);
        }
      }
    });
  });

  // remove any rates in controlled channels that is the same in uncontrolled channels
  otherRates.map((eachRate) => {
    baseRates.map((eachBaseRates) => {
      if (compareJSONArrays(eachRate.rates, eachBaseRates.rates)) {
        otherRates.splice(otherRates.indexOf(eachRate), 1);
      }
    });
  });

  baseRates.map((eachRate) => {
    // Desired Order
    const order = { Day: 1, Night: 2, Free: 3, Anytime: 4 };
    const newArray = [...eachRate.rates];
    newArray.sort((a, b) => order[a.rate_code] - order[b.rate_code]);
    eachRate.rates = newArray;
  });

  return {
    baseRates,
    exportRates,
    otherRates,
  };
};

/**
 *  compare exact 2 JSONobject arrays
 */
function compareJSONArrays(arr1: any[], arr2: any[]): boolean {
  // Check if lengths are equal
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Compare elements
  for (let i = 0; i < arr1.length; i++) {
    // For simplicity, this implementation assumes strict equality (===).
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
      return false; // Arrays are not equal
    }
  }

  return true; // Arrays are equal
}
