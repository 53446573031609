import { Container } from "@mui/material";
import ZLogo from "assets/z-header-logo-40.png";
import ZLogoLarge from "assets/z-header-logo-55.png";
import { ROUTES } from "domain/configuration";
import { getFlickSignUpAction } from "domain/core/actions";
import { setFlickUserId, setPromoCode } from "domain/core/reducers";
import { PromoCookie } from "domain/core/types";
import { fetchPromoCookie } from "lib/util";
import qs from "qs";
import React, { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { Notification } from "ui/components/Notification";
import PartialSignUpDialog from "ui/compounds/PartialSignUpDialog";

import { Header } from "./components/Header/Header";
import { Address } from "./pages/Address";
import { Details } from "./pages/Details";
import { Payment } from "./pages/Payment";
import { Success } from "./pages/Success";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const SetPromoCodeValue = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const promoCookie: PromoCookie = fetchPromoCookie();
    if (promoCookie !== undefined) {
      dispatch(setPromoCode(promoCookie["promoCode"]));
    }
  }, []);

  return null;
};

const RouteToLastLocation = () => {
  const dispatch = useAppDispatch();

  const { currentFormStep: storeCurrentFormStep } = useAppSelector(
    (store) => store.coreData,
  );
  const location = useLocation();
  const navigate = useNavigate();
  const signUpUUID = qs
    .parse(location.search, { ignoreQueryPrefix: true })
    .signUpUUID?.toString();
  // dont use params when matching steps for routing as they cause page refresh dialogues.
  const [currentFormStep] = storeCurrentFormStep.split("?");

  if (signUpUUID) {
    dispatch(setFlickUserId(signUpUUID));
    dispatch(getFlickSignUpAction.request());
  }

  if (currentFormStep !== location.pathname) {
    navigate(storeCurrentFormStep);
  }

  return null;
};

const RootLayout = () => {
  return (
    <>
      <ScrollToTop />
      <RouteToLastLocation />
      <PartialSignUpDialog />
      <SetPromoCodeValue />
      <Notification />
      <Container component="nav">
        <a href={process.env.REACT_APP_Z_WEBSITE_URL}>
          <img
            alt="Z Electricity for NZ - Logo"
            srcSet={`
              ${ZLogo} 40w, 
              ${ZLogoLarge} 55w
              `}
            sizes="(min-width: 1200px) 55px, 40px"
          />
        </a>
      </Container>
      <Header />
      <Outlet />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route path={ROUTES.details} element={<Details />} />
      <Route path={ROUTES.payment} element={<Payment />} />
      <Route path={ROUTES.success} element={<Success />} />
      {/* Default route '/' - needs to be last - first to match is served */}
      <Route path={ROUTES.address} element={<Address />} />
    </Route>,
  ),
);

export const App = () => {
  return <RouterProvider router={router}></RouterProvider>;
};
