import { List, ListItem } from "@mui/material";
import React from "react";
// eslint-disable-next-line import/no-unresolved
import ReactMarkdown from "react-markdown";
import { COLORS } from "ui/theme";

type BulletPointListProps = {
  listItems: string[];
};

export const BulletPointList: React.FC<BulletPointListProps> = ({
  listItems,
}) => {
  return (
    <List
      sx={{
        listStyle: "disc",
        marginLeft: "1.75rem",
        color: COLORS.charcoal,
      }}
    >
      {listItems?.map((listItem, index) => {
        return (
          <ListItem
            className="bullet-list_item"
            sx={{
              display: "list-item",
            }}
            key={`list-item-${index}`}
          >
            <ReactMarkdown>{listItem}</ReactMarkdown>
          </ListItem>
        );
      })}
    </List>
  );
};
