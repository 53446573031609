import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as CheckmarkIcon } from "assets/circular-orange-check.svg";
import React from "react";

import { BulletPointList } from "./BulletPointList";

type CheckedListProps = {
  listItems: {
    primaryProofPoint: string;
    secondaryProofPoints?: string[];
  }[];
};

export const CheckedList: React.FC<CheckedListProps> = ({ listItems }) => {
  return (
    <List>
      {listItems?.map((listItem) => {
        return (
          <ListItem>
            <ListItemIcon>
              <CheckmarkIcon />
            </ListItemIcon>
            <ListItemText>
              {listItem.primaryProofPoint}
              {Boolean(listItem.secondaryProofPoints) && (
                <BulletPointList listItems={listItem.secondaryProofPoints} />
              )}
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};
