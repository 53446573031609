/* eslint-disable max-params */
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ReactComponent as EVAtHomeIcon } from "assets/icon-ev-at-home.svg";
import { ReactComponent as EVWithSmartChargerIcon } from "assets/icon-ev-with-charger.svg";
import { ReactComponent as FuelBackHomeIcon } from "assets/icon-fuel-back-home.svg";
import { setUsageType } from "domain/core/reducers";
import {
  DialogTypes,
  ProductName,
  RateCard,
  UsageType,
} from "domain/core/types";
import { media, sizes } from "lib/styles";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { styled } from "styled-components";
import { AccordionWrapper } from "ui/components/Accordion/AccordionWrapper";
import {
  EVSmartChargerDialog,
  EVTCDialog,
  FuelTCDialog,
} from "ui/components/Dialog/PlanCardDialogs";
import { PlanCard } from "ui/components/PlanCard/PlanCard";
import { RowLayoutLeft } from "ui/components/StyledComponents";
import theme, { PADDING } from "ui/theme";

import { ExtraPlanPageText } from "../planDetails/ExtraPlanPageText";
import evAtHomePlanAndSmartChargerCopy from "../static/evAtHomePlanAndSmartChargerCopy.json";
import evAtHomePlanCopy from "../static/evAtHomePlanCopy.json";
import fuelBackHomePlanCopy from "../static/fuelBackHomePlanCopy.json";

const {
  evPlanName,
  evPlanCardSubheading,
  evPlanTermsAndConditionsCopy,
  evPlanProofPointsCopy,
} = evAtHomePlanCopy;

const {
  evSmartChargerPlanName,
  evSmartChargerShortPlanName,
  evSmartChargerPlanCardSubheading,
  evSmartChargerPlanEligibilityTermsCopy,
  evSmartChargerPlanProofPointsCopy,
} = evAtHomePlanAndSmartChargerCopy;

const {
  fuelPlanCardSubheading,
  fuelPlanName,
  fuelPlanProofPointsCopy,
  fuelPlanTermsAndConditionsCopy,
} = fuelBackHomePlanCopy;

const PlanCardWrapper = styled.section`
  display: flex;
  flex-direction: column;
  ${media.md`
    flex-direction: row;
    justify-content: space-evenly;
  `}
`;

const SRowLayoutLeft = styled(RowLayoutLeft)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: ${PADDING.xxLarge};
  margin-top: ${PADDING.xxLarge};

  > * {
    margin-right: ${PADDING.medium};
  }
`;

const SSelect = styled(Select)`
  font-weight: 600;
`;

export const PlanChooser = ({ rateCards, onJoinNowClicked, route }) => {
  const dispatch = useAppDispatch();

  const [GSTInclusive, setGSTInclusive] = useState(0);
  const [dialogOpen, setDialogOpen] = useState({
    open: false,
    dialogType: "",
  });

  const {
    user: { property },
  } = useAppSelector((store) => store.coreData);

  const handleUsageTypeChange = (event: SelectChangeEvent<UsageType>) => {
    dispatch(setUsageType(event.target.value as UsageType));
  };

  const handleGSTChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGSTInclusive(Number(event.target.value));
  };

  const handleCloseDialog = () => {
    setDialogOpen({
      open: false,
      dialogType: "",
    });
  };

  const exportChannel = rateCards[0].usage_rates.some((rate) =>
    rate.channels.some((channel) => channel.flow_direction === "import"),
  );

  const controlledChannel = rateCards[0].usage_rates.some((rate) =>
    rate.channels.some((channel) => channel.content_code === "CN"),
  );

  const activeUsageTypes: UsageType[] = [
    ...new Set(rateCards.map((rateCard: RateCard) => rateCard.usage_type)),
  ] as UsageType[];

  const renderDialog = useMemo(() => {
    // Define JSX for each dialog type
    const dialogComponents = {
      [DialogTypes.evTermsAndConditions]: (
        <EVTCDialog
          title={evPlanTermsAndConditionsCopy.heading}
          visible={true}
          onClose={handleCloseDialog}
        />
      ),
      [DialogTypes.fuelTermsAndConditions]: (
        <FuelTCDialog
          title={fuelPlanTermsAndConditionsCopy.heading}
          visible={true}
          onClose={handleCloseDialog}
        />
      ),
      [DialogTypes.evSmartChargerTerms]: (
        <EVSmartChargerDialog
          title={evSmartChargerPlanEligibilityTermsCopy.heading}
          visible={true}
          onClose={handleCloseDialog}
          GSTInclusive={Boolean(GSTInclusive)}
        />
      ),
    };

    // Render the appropriate dialog component based on dialogOpen state
    return dialogOpen.open ? (
      dialogComponents[dialogOpen.dialogType] || <></>
    ) : (
      <></>
    );
  }, [dialogOpen]);

  const isMediumScreenSize = useMediaQuery(theme.breakpoints.up(sizes.md));
  const renderPlanCards = useMemo(() => {
    const PlanCardEV = (
      <PlanCard
        planName={evPlanName}
        shortPlanName={evPlanName}
        planIcon={<EVAtHomeIcon />}
        contentHeading={evPlanCardSubheading}
        joinNowRoute={route}
        onJoinNowClicked={(
          e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        ) => {
          e.preventDefault();
          onJoinNowClicked(ProductName.Z_EV);
        }}
        exportChannel={exportChannel}
        controlledChannel={controlledChannel}
        planProofPoints={evPlanProofPointsCopy}
        onTAndCDialogOpen={() => {
          setDialogOpen({
            open: true,
            dialogType: DialogTypes.evTermsAndConditions,
          });
        }}
      >
        <AccordionWrapper
          planName={evPlanName}
          rateCards={rateCards}
          GSTInclusive={Boolean(GSTInclusive)}
          usageType={property.usageType}
        />
      </PlanCard>
    );

    const PlanCardEVSmartCharger = (
      <PlanCard
        planName={evSmartChargerPlanName}
        shortPlanName={evSmartChargerShortPlanName}
        planIcon={<EVWithSmartChargerIcon />}
        contentHeading={evSmartChargerPlanCardSubheading}
        joinNowRoute={route}
        onJoinNowClicked={(
          e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        ) => {
          e.preventDefault();
          onJoinNowClicked("z_ev_smart_charger");
        }}
        exportChannel={exportChannel}
        controlledChannel={controlledChannel}
        planProofPoints={evSmartChargerPlanProofPointsCopy}
        onTAndCDialogOpen={() => {
          setDialogOpen({
            open: true,
            dialogType: DialogTypes.evTermsAndConditions,
          });
        }}
        onSmartChargerDialogOpen={() => {
          setDialogOpen({
            open: true,
            dialogType: DialogTypes.evSmartChargerTerms,
          });
        }}
      >
        <AccordionWrapper
          planName={evSmartChargerPlanName}
          rateCards={rateCards}
          GSTInclusive={Boolean(GSTInclusive)}
          usageType={property.usageType}
        />
      </PlanCard>
    );

    const PlanCardFuel = (
      <PlanCard
        planName={fuelPlanName}
        shortPlanName={fuelPlanName}
        planIcon={<FuelBackHomeIcon />}
        contentHeading={fuelPlanCardSubheading}
        joinNowRoute={route}
        onJoinNowClicked={(
          e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        ) => {
          e.preventDefault();
          onJoinNowClicked(ProductName.Z_ELECTRIC_REWARDS);
        }}
        exportChannel={exportChannel}
        controlledChannel={controlledChannel}
        planProofPoints={fuelPlanProofPointsCopy}
        onTAndCDialogOpen={() => {
          setDialogOpen({
            open: true,
            dialogType: DialogTypes.fuelTermsAndConditions,
          });
        }}
      >
        <AccordionWrapper
          planName={fuelPlanName}
          rateCards={rateCards}
          GSTInclusive={Boolean(GSTInclusive)}
          usageType={property.usageType}
        />
      </PlanCard>
    );

    return (
      <PlanCardWrapper>
        {isMediumScreenSize ? (
          <>
            {PlanCardEV}
            {PlanCardEVSmartCharger}
            {PlanCardFuel}
          </>
        ) : (
          <>
            {PlanCardEVSmartCharger}
            {PlanCardEV}
            {PlanCardFuel}
          </>
        )}
      </PlanCardWrapper>
    );
  }, [isMediumScreenSize, GSTInclusive, rateCards, property.usageType]);

  return (
    <>
      <Typography variant="h3">Choose your plan</Typography>
      <Typography variant="h4">
        Our plans are designed around your home and vehicle.
      </Typography>
      <ExtraPlanPageText />
      <SRowLayoutLeft>
        <Typography variant="subtitle1">Pricing based on:</Typography>
        <SSelect
          autoWidth
          defaultValue={property.usageType}
          onChange={handleUsageTypeChange}
        >
          {activeUsageTypes.map((usageType) => (
            <MenuItem value={usageType}>{usageType} power user</MenuItem>
          ))}
        </SSelect>
        <SSelect
          autoWidth
          defaultValue={GSTInclusive}
          onChange={handleGSTChange}
        >
          <MenuItem value={1}>incl. gst</MenuItem>
          <MenuItem value={0}>excl. gst</MenuItem>
        </SSelect>
      </SRowLayoutLeft>
      {renderPlanCards}
      {renderDialog}
    </>
  );
};
