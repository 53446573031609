import { RateCard, Rates } from "domain/core/types";

import { HOCAccordion } from "../HOC/HOCAccordion";
import { BaseAccordionProps } from "./BaseAccordion";
import {
  EVPlanAdditionalMetersAccordionDetails,
  EVPlanYourPricesAccordionDetails,
  FuelPlanAdditionalMetersAccordionDetails,
  FuelPlanYourPricesAccordionDetails,
} from "./PlanAccordionContent";

const EVYourPricesAccordion = HOCAccordion<
  BaseAccordionProps,
  {
    sortedBaseRates: Rates[];
    exportRates: Rates[];
    rateCard: RateCard;
    GSTInclusive: boolean;
  }
>(EVPlanYourPricesAccordionDetails);

const EVAdditionalMetersAccordion = HOCAccordion<
  BaseAccordionProps,
  {
    sortedOtherRates: Rates[];
    GSTInclusive: boolean;
  }
>(EVPlanAdditionalMetersAccordionDetails);

const FuelYourPricesAccordion = HOCAccordion<
  BaseAccordionProps,
  {
    sortedBaseRates: Rates[];
    exportRates: Rates[];
    rateCard: RateCard;
    GSTInclusive: boolean;
  }
>(FuelPlanYourPricesAccordionDetails);

const FuelAdditionalMetersAccordion = HOCAccordion<
  BaseAccordionProps,
  {
    sortedOtherRates: Rates[];
    GSTInclusive: boolean;
  }
>(FuelPlanAdditionalMetersAccordionDetails);

export {
  EVAdditionalMetersAccordion,
  EVYourPricesAccordion,
  FuelAdditionalMetersAccordion,
  FuelYourPricesAccordion,
};
