import { Typography } from "@mui/material";
import { RateCard, Rates, Timetables } from "domain/core/types";
import { financiallyRound } from "lib/util";
import React, { useCallback } from "react";
import { styled } from "styled-components";
import { PADDING } from "ui/theme";

interface CurrentChargesProps {
  sortedBaseRates: Rates[];
  exportRates: Rates[];
  rateCard: RateCard;
  GSTInclusive: boolean;
}

const Container = styled.div`
  padding: 0;
`;

const RatesDisplayContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const CurrentCharges: React.FC<CurrentChargesProps> = ({
  sortedBaseRates,
  exportRates,
  rateCard,
  GSTInclusive,
}) => {
  const getAdjustedToCentsGSTPrice = useCallback(
    (value: string) => {
      if (GSTInclusive) {
        return financiallyRound(parseFloat(value) * 100 * 1.15, 2);
      }
      return financiallyRound(parseFloat(value) * 100, 2);
    },
    [GSTInclusive],
  );

  const supplyCharges = rateCard?.subscription_rates[0];

  const simpleRateTimetableDescription = (
    rateTimetables: Timetables[],
    rateIndex: number,
  ) => {
    if (rateTimetables[rateIndex].rate_code === "Night") {
      return " (9pm-7am)";
    }
    if (rateTimetables[rateIndex].rate_code === "Day") {
      return " (7am-9pm)";
    }
    if (rateTimetables[rateIndex].rate_code === "Free") {
      return " (3am-6am)";
    }
    return "";
  };

  return (
    <Container>
      <Typography
        variant="subtitle2"
        component="p"
        sx={{ paddingBottom: PADDING.small }}
      >
        Usage
      </Typography>
      {sortedBaseRates?.map((baseRate) => {
        const rateTimetables = rateCard?.timetables;
        // Desired Order
        const order = { Day: 1, Night: 2, Free: 3, Anytime: 4 };
        // Resort rateTimetables in desired Order
        const rateTimeTablesToSort = [...rateTimetables];
        rateTimeTablesToSort.sort(
          (a, b) => order[a.rate_code] - order[b.rate_code],
        );

        return (
          <>
            {baseRate?.rates.map((rate, rateIndex) => (
              <>
                <RatesDisplayContainer>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ padding: "0", flexGrow: 1, flexBasis: "132px" }}
                  >
                    {`${rate.rate_code} rate`}
                    {rateTimeTablesToSort[rateIndex] && (
                      <span key={`rateRate-timetable-${rateIndex}`}>
                        {simpleRateTimetableDescription(
                          rateTimeTablesToSort,
                          rateIndex,
                        )}
                      </span>
                    )}
                    :
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{
                      padding: 0,
                      flexShrink: 1,
                      flexBasis: "112px",
                      textAlign: "right",
                      "@media only screen and (min-width: 870px) and (max-width: 1073px)":
                        {
                          textAlign: "left",
                        },
                      "@media only screen and (max-width: 371px)": {
                        textAlign: "left",
                      },
                    }}
                  >
                    {getAdjustedToCentsGSTPrice(rate.total)}c/kWh
                  </Typography>
                </RatesDisplayContainer>
              </>
            ))}
          </>
        );
      })}
      {supplyCharges && (
        <RatesDisplayContainer style={{ marginTop: PADDING.large }}>
          <Typography
            variant="subtitle2"
            component="p"
            sx={{ flexGrow: 1, flexBasis: "132px" }}
          >
            Daily charge
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "1rem",
              lineHeight: 1.5,
              flexShrink: 1,
              flexBasis: "112px",
              textAlign: "right",
              "@media only screen and (min-width: 870px) and (max-width: 1073px)":
                {
                  textAlign: "left",
                },
              "@media only screen and (max-width: 371px)": {
                textAlign: "left",
              },
            }}
            component="p"
          >
            {getAdjustedToCentsGSTPrice(supplyCharges.rates[0].total)}c/day
          </Typography>
        </RatesDisplayContainer>
      )}
      {exportRates.length > 0 && (
        <>
          <Typography
            variant="subtitle2"
            component="p"
            style={{ flexShrink: 0 }}
            sx={{ marginTop: PADDING.large, paddingBottom: PADDING.small }}
          >
            Solar power exported
          </Typography>
          <RatesDisplayContainer>
            <Typography
              variant="body1"
              component="p"
              style={{ flexGrow: 1, flexBasis: "132px" }}
              sx={{ padding: "0" }}
            >
              Solar buy-back:
            </Typography>
            <Typography
              variant="body2"
              component="p"
              sx={{
                flexShrink: 1,
                flexBasis: "182px",
                padding: 0,
                textAlign: "right",
                "@media only screen and (min-width: 1074px) and (max-width: 1417px)":
                  {
                    flexBasis: "112px",
                  },
                "@media only screen and (min-width: 370px) and (max-width: 440px)":
                  {
                    flexBasis: "112px",
                  },
                "@media only screen and (min-width: 870px) and (max-width: 1073px)":
                  {
                    textAlign: "left",
                  },
                "@media only screen and (max-width: 371px)": {
                  textAlign: "left",
                },
              }}
            >
              <span style={{ lineBreak: "strict" }}>Wholesale power </span>
              rate*/kWh
            </Typography>
          </RatesDisplayContainer>
          {parseFloat(exportRates[0].rates[0].network) > 0 && (
            <RatesDisplayContainer>
              <Typography
                variant="body1"
                component="p"
                sx={{ padding: "0", flexGrow: 1, flexBasis: "132px" }}
              >
                Less network charge:
              </Typography>
              <Typography
                variant="body2"
                component="p"
                sx={{
                  padding: 0,
                  flexShrink: 1,
                  flexBasis: "112px",
                  textAlign: "right",
                  "@media only screen and (min-width: 870px) and (max-width: 1073px)":
                    {
                      textAlign: "left",
                    },
                  "@media only screen and (max-width: 371px)": {
                    textAlign: "left",
                  },
                }}
              >
                {getAdjustedToCentsGSTPrice(exportRates[0].rates[0].network)}
                c/kWh
              </Typography>
            </RatesDisplayContainer>
          )}
          <p>
            *Wholesale power rates are a variable rate that changes every 30
            minutes.
          </p>
        </>
      )}
    </Container>
  );
};
