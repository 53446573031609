import { ProductName, RateCard, UsageType } from "domain/core/types";
import React, { memo } from "react";
import {
  EVAdditionalMetersAccordion,
  EVYourPricesAccordion,
  FuelAdditionalMetersAccordion,
  FuelYourPricesAccordion,
} from "ui/components/Accordion/PlanCardAccordions";

import evAtHomePlanAndSmartChargerCopy from "../../compounds/static/evAtHomePlanAndSmartChargerCopy.json";
import evAtHomePlanCopy from "../../compounds/static/evAtHomePlanCopy.json";
import fuelBackHomePlanCopy from "../../compounds/static/fuelBackHomePlanCopy.json";

const { evPlanName } = evAtHomePlanCopy;
const { evSmartChargerPlanName } = evAtHomePlanAndSmartChargerCopy;
const { fuelPlanName } = fuelBackHomePlanCopy;

import { getPlanRateCards, sortUsageRatesPlan } from "./AccordionWrapper.utils";

interface AccordionWrapperProps {
  planName: string;
  rateCards: RateCard[];
  GSTInclusive: boolean;
  usageType: UsageType | "standard" | "low";
}

export const AccordionWrapper: React.FC<AccordionWrapperProps> = memo(
  ({ planName, rateCards, GSTInclusive, usageType }) => {
    const fuelPlanRateCards = getPlanRateCards(
      rateCards,
      ProductName.Z_ELECTRIC_REWARDS,
      usageType,
    );
    const evPlanRateCards = getPlanRateCards(
      rateCards,
      ProductName.Z_EV,
      usageType,
    );

    if (planName === evPlanName || planName === evSmartChargerPlanName) {
      const usageRates = evPlanRateCards[0]?.usage_rates;
      const evPlanSortedRates = usageRates
        ? sortUsageRatesPlan(usageRates)
        : undefined;

      return (
        <>
          {evPlanRateCards && evPlanSortedRates && (
            <EVYourPricesAccordion
              planName={evPlanName}
              heading={
                <>
                  Your prices <wbr />
                  (main meter)
                </>
              }
              sortedBaseRates={evPlanSortedRates.baseRates}
              exportRates={evPlanSortedRates.exportRates}
              rateCard={evPlanRateCards[0]}
              GSTInclusive={Boolean(GSTInclusive)}
            />
          )}
          {evPlanSortedRates &&
            Boolean(evPlanSortedRates?.otherRates?.length) && (
              <EVAdditionalMetersAccordion
                planName={evPlanName}
                heading={
                  <>
                    Additional <wbr />
                    meter(s)
                  </>
                }
                sortedOtherRates={evPlanSortedRates.otherRates}
                GSTInclusive={Boolean(GSTInclusive)}
              />
            )}
        </>
      );
    }

    if (planName === fuelPlanName) {
      const usageRates = fuelPlanRateCards[0]?.usage_rates;
      const fuelPlanSortedRates = usageRates
        ? sortUsageRatesPlan(usageRates)
        : undefined;

      return (
        <>
          {fuelPlanRateCards && fuelPlanSortedRates && (
            <FuelYourPricesAccordion
              planName={fuelPlanName}
              heading={
                <>
                  Your prices <wbr />
                  (main meter)
                </>
              }
              sortedBaseRates={fuelPlanSortedRates.baseRates}
              exportRates={fuelPlanSortedRates.exportRates}
              rateCard={fuelPlanRateCards[0]}
              GSTInclusive={Boolean(GSTInclusive)}
            />
          )}
          {fuelPlanSortedRates &&
            Boolean(fuelPlanSortedRates?.otherRates?.length) && (
              <FuelAdditionalMetersAccordion
                planName={fuelPlanName}
                heading="Additional meter(s)"
                sortedOtherRates={fuelPlanSortedRates.otherRates}
                GSTInclusive={Boolean(GSTInclusive)}
              />
            )}
        </>
      );
    }
    return <></>;
  },
);
