import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import EvChargerSandIcon from "assets/ev-charger-color-sand.png";
import EvChargerSnowIcon from "assets/ev-charger-color-snow.png";
import EvChargerStoneIcon from "assets/ev-charger-color-stone.png";
import EvChargerVolcanicIcon from "assets/ev-charger-color-volcanic.png";
import { ReactComponent as EvChargerOn } from "assets/ev-charger-on.svg";
import EvChargerImageSand from "assets/ev-charger-sand.png";
import EvChargerImageSnow from "assets/ev-charger-snow.png";
import EvChargerImageStone from "assets/ev-charger-stone.png";
import EvChargerImageVolcanic from "assets/ev-charger-volcanic.png";
import { setEvChargerColor } from "domain/core/reducers";
import { EvChargerColors } from "domain/core/types";
import React, { ChangeEvent, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { styled } from "styled-components";
import {
  CapitalisedTypography,
  RowLayout,
} from "ui/components/StyledComponents";
import theme, { COLORS, PADDING } from "ui/theme";

const SRowLayout = styled(RowLayout)`
  align-items: center;
  padding-top: ${PADDING.large};
  padding-bottom: ${PADDING.large};
`;

const SRadioGroup = styled(RadioGroup)`
  padding-bottom: ${PADDING.medium};

  & :first-child {
    margin-left: 0;
  }

  & :last-child {
    margin-right: 0;
  }
`;

const CheckedIcon = styled.img`
  outline: 4px solid ${COLORS.orange};
  outline-offset: -4px;
  border-radius: 50%;
`;

const ChargerImages = styled.div`
  position: relative;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

const Charger = styled.img`
  position: relative;
`;

const ChargerSelected = styled(EvChargerOn)`
  position: absolute;
  top: 85px;
  left: 42px;
`;

export const EvChargerAddOn: React.FC = () => {
  const dispatch = useAppDispatch();

  const { productMetadata } = useAppSelector((store) => store.coreData);

  const chargerIcons = {
    snow: EvChargerSnowIcon,
    sand: EvChargerSandIcon,
    stone: EvChargerStoneIcon,
    volcanic: EvChargerVolcanicIcon,
  };

  const chargerImages = {
    snow: EvChargerImageSnow,
    sand: EvChargerImageSand,
    stone: EvChargerImageStone,
    volcanic: EvChargerImageVolcanic,
  };

  const handleEvColorChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as EvChargerColors;
      dispatch(setEvChargerColor(value));
    },
    [productMetadata.evChargerColor],
  );

  return (
    <>
      <Typography variant="h4">What colour charger would you like?</Typography>
      <SRowLayout>
        <ChargerImages>
          <Charger
            srcSet={chargerImages[productMetadata.evChargerColor]}
            src={chargerImages[productMetadata.evChargerColor]}
            alt="Evnex smart charger in snow color"
          />
          {productMetadata.includeEVcharger && (
            <ChargerSelected>
              <EvChargerOn />
            </ChargerSelected>
          )}
        </ChargerImages>
      </SRowLayout>
      <SRadioGroup
        row
        aria-labelledby="charger-color-radio-buttons"
        name="charger-colors"
        onChange={handleEvColorChange}
        defaultValue={productMetadata.evChargerColor}
      >
        {Object.values(EvChargerColors).map((color) => {
          return (
            <FormControlLabel
              value={color}
              control={
                <Radio
                  color="secondary"
                  checkedIcon={
                    <CheckedIcon
                      srcSet={chargerIcons[color]}
                      src={chargerIcons[color]}
                      alt={`Ev charger ${color} color`}
                    />
                  }
                  icon={
                    <img
                      srcSet={chargerIcons[color]}
                      src={chargerIcons[color]}
                      alt={`Ev charger ${color} color`}
                    />
                  }
                />
              }
              label={
                <CapitalisedTypography variant="subtitle2">
                  {color}
                </CapitalisedTypography>
              }
              labelPlacement="bottom"
            />
          );
        })}
      </SRadioGroup>
    </>
  );
};
