import { Button as MuiButton } from "@mui/material";
import React from "react";
import { styled } from "styled-components";
import { COLORS, PADDING } from "ui/theme";

type ButtonSize = "default" | "large";
type ButtonType = "default" | "secondary";

const Button = styled((props) => (
  <MuiButton disableElevation color="secondary" {...props} />
))<{
  buttonsize?: ButtonSize;
  buttontype?: ButtonType;
}>`
  background: linear-gradient(90deg, #ed560e 0%, #f89c15 95.55%);
  margin: ${PADDING.large} 0;
  height: 3rem;
  border-radius: 100px;
  border: 2px solid;
  font-weight: 600;
  font-size: 18px;
  text-transform: none;
  padding: 12px 26px;
  line-height: 1;
  ${(props) =>
    `color: ${props.buttontype === "secondary" ? COLORS.orange : COLORS.white};
  `};
`;

export default Button;
