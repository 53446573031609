import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { VerificationType } from "domain/core/types";
import { formatNumber } from "lib/util";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCodeInput from "react-code-input";
import { styled } from "styled-components";
import { MLink } from "ui/components/StyledComponents";
import { COLORS } from "ui/theme";

interface Props {
  visible: boolean;
  loading: boolean;
  error?: string;
  verificationMethod: {
    type: VerificationType;
    value: string;
  };
  onConfirmPress(code: string): void;
  onClose(): void;
  onResendPress(): void;
}

const Spacer = styled.div`
  height: 1rem;
`;

const styleProps = {
  inputStyle: {
    fontFamily: "Roboto Mono, monospace",
    margin: "4px",
    height: "60px",
    width: "50px",
    borderRadius: "3px",
    fontSize: "20px",
    paddingLeft: "20px",
    backgroundColor: COLORS.lightGrey,
    border: "0px",
    borderBottom: `1px solid ${COLORS.grey}`,
    MozAppearance: "textfield",
  },
};

const DialogContentWrapper = styled(DialogContent)`
  place-content: center center;
  text-align: center;
`;

const StyledCircularProgress = styled(CircularProgress)`
  align-content: center;
  margin-bottom: 1rem;
`;

export default function VerificationDialog(props: Props) {
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [timer, setTimer] = useState<number>(30);
  const codeInputRef = useRef(undefined);

  useEffect(() => {
    const t = setTimeout(() => setTimer(timer - 1), 1000);
    return () => clearTimeout(t);
  }, [timer, setTimer]);

  const clearInput = useCallback(() => {
    setCode("");
    // Work around to clear the code, React Code Input is not a controlled component
    // FIXME: update the code input lib being used
    if (codeInputRef && codeInputRef.current) {
      // @ts-ignore
      codeInputRef.current.state.input.forEach((_input, index) => {
        // @ts-ignore
        codeInputRef.current.state.input[index] = "";
      });
    }
  }, [codeInputRef]);

  const handleOnCodeChange = useCallback(
    (value: string) => {
      setCode(value);
      setError("");
      if (value.length === 4) {
        props.onConfirmPress(value);
        clearInput();
      }
    },
    [props, clearInput],
  );

  const handleResendPress = useCallback(() => {
    clearInput();
    setTimer(30);

    props.onResendPress();
  }, [props, clearInput]);

  const renderDialogContent = useCallback(() => {
    if (props.loading) {
      return (
        <div>
          <DialogTitle id="verify-code-dialog">
            <Typography variant="h5">Verifying your details...</Typography>
          </DialogTitle>
          <DialogContentWrapper>
            <StyledCircularProgress size={50} />
          </DialogContentWrapper>
        </div>
      );
    }
    return (
      <form autoComplete="off">
        <DialogTitle id="verify-code-dialog">
          <Typography variant="h5">
            Verify your {props.verificationMethod.type}
          </Typography>
        </DialogTitle>
        <DialogContentWrapper>
          <Typography variant="body1">
            Please enter the 4-digit code sent to your{" "}
            {props.verificationMethod.type}:
          </Typography>
          <Typography variant="body2">
            {formatNumber(props.verificationMethod.value)}
          </Typography>
          <Spacer />
          <ReactCodeInput
            fields={4}
            ref={codeInputRef}
            onChange={handleOnCodeChange}
            value={code}
            inputMode="numeric"
            {...styleProps}
          />
          <DialogContentText>
            <Typography variant="caption" color="error">
              {props.error || error}
            </Typography>
          </DialogContentText>
          {timer > 0 ? (
            <Typography>
              Resend code in 00:{timer.toString().padStart(2, "0")}
            </Typography>
          ) : (
            <MLink
              color="textPrimary"
              component="button"
              variant="body1"
              onClick={handleResendPress}
            >
              Resend
            </MLink>
          )}
          <Spacer />
          <Spacer />
        </DialogContentWrapper>
      </form>
    );
  }, [
    props.verificationMethod,
    props.loading,
    props.error,
    error,
    handleOnCodeChange,
    handleResendPress,
    timer,
    code,
  ]);

  return (
    <Dialog
      open={props.visible}
      onClose={props.onClose}
      aria-labelledby="verify-code-dialog"
    >
      {renderDialogContent()}
    </Dialog>
  );
}
