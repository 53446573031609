import { DialogContent as MuiDialogContent, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { COLORS } from "ui/theme";

export const DialogContent: React.FC<PropsWithChildren> = ({ children }) => {
  return <MuiDialogContent>{children}</MuiDialogContent>;
};

interface DialogContentSectionProps extends PropsWithChildren {
  heading: string;
}

export const DialogContentSection: React.FC<DialogContentSectionProps> = ({
  heading,
  children,
}) => {
  return (
    <section>
      <Typography variant="h4">{heading}</Typography>
      {children}
    </section>
  );
};

export const DialogContentSubSection: React.FC<DialogContentSectionProps> = ({
  heading,
  children,
}) => {
  return (
    <article>
      <Typography variant="h5" sx={{ color: COLORS.charcoal }}>
        {heading}
      </Typography>
      {children}
    </article>
  );
};
