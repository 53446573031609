import { Typography } from "@mui/material";
import React from "react";
import { useAppSelector } from "store/storeHooks";

export const ExtraPlanPageText: React.FC = () => {
  const {
    user: { property },
  } = useAppSelector((store) => store.coreData);

  return (
    <>
      {/* <Typography variant="body1" component="p">
        Each April we will review your electricity prices, in line with the
        wider industry's annual price adjustments.
      </Typography> */}
      <Typography variant="body1" component="p">
        EV owners who charge at home are typically standard users. Typically,{" "}
        {property.usageType === "low" ? "standard" : "low"} users spend{" "}
        {property.usageType === "low" ? "over" : "under"} $215 a month on power
        bills using {property.usageType === "low" ? "more" : "less"} than
        8,000-9,000 kWh per year, depending on your region. If this sounds like
        you change your power user type below.
      </Typography>
    </>
  );
};
