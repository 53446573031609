import { Typography } from "@mui/material";
import { financiallyRound } from "lib/util";
import React, { memo } from "react";

interface AdditionalMetersProps {
  sortedOtherRates: any[];
  GSTInclusive: boolean;
}

export const AdditionalMeters: React.FC<AdditionalMetersProps> = memo(
  ({ sortedOtherRates, GSTInclusive }) => {
    const getAdjustedToCentsGSTPrice = (value: string) => {
      if (GSTInclusive) {
        return financiallyRound(parseFloat(value) * 100 * 1.15, 2);
      }
      return financiallyRound(parseFloat(value) * 100, 2);
    };

    return (
      <>
        <Typography component="p">
          At your address, you have an additional meter(s) which is priced
          separately:
        </Typography>
        <ul style={{ paddingInlineStart: "20px" }}>
          {sortedOtherRates.map(({ channel, rates }) => {
            return rates.map((rate) => {
              return (
                <li>
                  Controlled meter{" "}
                  {channel.period_of_availability &&
                    `(${channel.period_of_availability})`}{" "}
                  will be charged at {getAdjustedToCentsGSTPrice(rate.total)}
                  c/kWh {GSTInclusive ? "incl." : "excl."} GST.
                </li>
              );
            });
          })}
        </ul>
        <Typography component="p">
          The prices above only apply to the meters listed. Any other meter
          usage will be priced as outlined in the table above.
        </Typography>
      </>
    );
  },
);
