import React from "react";

import { BaseAccordion, BaseAccordionProps } from "../Accordion";

/** Higher-order component (HOC) for wrapping Accordions */
export const HOCAccordion = <P extends BaseAccordionProps, T>(
  WrappedComponent: React.ComponentType<T>,
) => {
  const MyComponent: React.FC<P & T> = (props) => {
    return (
      <BaseAccordion {...props}>
        <WrappedComponent {...props} />
      </BaseAccordion>
    );
  };

  return MyComponent;
};
