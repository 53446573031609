/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from "@mui/material";
import React from "react";
import { styled } from "styled-components";
import { COLORS, PADDING } from "ui/theme";

import {
  CompletionNextStepsCard,
  CompletionNextStepsDetails,
  CompletionNextStepsDigit,
} from "../StyledComponents";

type NextStepsCardProps = {
  cardStepNumber: string;
  cardHeading: string;
  cardText: string;
};

const CardHeader = styled.h4`
  font-size: ${28 / 16}rem;
  color: ${COLORS.indigo};
  margin-top: ${PADDING.large};
  margin-bottom: 13px;
  line-height: ${30 / 16}rem;
`;

export const NextStepsCard: React.FC<NextStepsCardProps> = ({
  cardStepNumber,
  cardHeading,
  cardText,
}) => {
  return (
    <CompletionNextStepsCard key={"card-next-steps-switching-in"}>
      <CompletionNextStepsDigit>
        <CardHeader style={{ paddingRight: "0.5rem", paddingLeft: "0.5rem" }}>
          {cardStepNumber}
        </CardHeader>
        <CardHeader>{cardHeading}</CardHeader>
      </CompletionNextStepsDigit>
      <CompletionNextStepsDetails>{cardText}</CompletionNextStepsDetails>
    </CompletionNextStepsCard>
  );
};
