import { Typography } from "@mui/material";
import FuelRewardsComplete from "assets/fuel-rewards-complete.png";
import { AddressType, CustomerFacingProductName } from "domain/core/types";
import { formatAddress } from "lib/util";
import React from "react";
import { CompletionExtraInfo } from "ui/components/CompletionExtraInfo";
import { NextStepsCard } from "ui/components/NextStepsCard";
import {
  CompletionCardContainer,
  ContentWrapper,
} from "ui/components/StyledComponents";
import { PADDING } from "ui/theme";

import fuelBackHomeCopy from "../static/fuelBackHomePlanCopy.json";

const { fuelBackHomeCompletionNextSteps, fuelBackHomeCompletionExtraInfo } =
  fuelBackHomeCopy;

const {
  completionExtraInfoHeading,
  completionExtraInfoParagraphs,
  completionExtraInfoLink,
} = fuelBackHomeCompletionExtraInfo;

interface FuelCompletionProps {
  preferredName: string;
  selectedProduct: string;
  address: AddressType;
  isSwitching: boolean;
}

export const FuelRewardsCompletion: React.FC<FuelCompletionProps> = ({
  preferredName,
  selectedProduct,
  address,
  isSwitching,
}) => {
  return (
    <>
      <ContentWrapper>
        <Typography
          variant="body1"
          sx={{
            paddingBottom: PADDING.small,
            paddingTop: PADDING.large,
          }}
        >
          Kia ora {preferredName},
        </Typography>
        <Typography display="inline" variant="body1">
          Your switch to Z's {CustomerFacingProductName[selectedProduct]} plan
          at{" "}
        </Typography>
        <Typography display="inline" variant="body2">
          {formatAddress(address)}{" "}
        </Typography>
        <Typography display="inline" variant="body1">
          is underway!
        </Typography>
        {isSwitching ? (
          <>
            <Typography variant="body1">
              It can take up to 5 working days to leave your old power company
              (sorry!), but we'll let you know once it's all sorted. Don't worry
              though - Electricity will always stay connected to{" "}
              {formatAddress(address)}.
            </Typography>
          </>
        ) : (
          <Typography variant="body1">
            If we have any questions, we'll get in touch.
          </Typography>
        )}
      </ContentWrapper>

      <CompletionCardContainer>
        {fuelBackHomeCompletionNextSteps.map(
          ({
            completionStepNumber,
            completionStepHeading,
            completionStepText,
          }) => (
            <NextStepsCard
              cardStepNumber={completionStepNumber}
              cardHeading={completionStepHeading}
              cardText={completionStepText}
            />
          ),
        )}
      </CompletionCardContainer>

      <Typography
        variant="body1"
        sx={{
          paddingBottom: PADDING.xLarge,
        }}
      >
        If you need to get in touch with our support centre we can identify your
        account by your name and phone number. You can now close this browser
        page.
      </Typography>

      <CompletionExtraInfo
        header={completionExtraInfoHeading}
        paragraphs={completionExtraInfoParagraphs}
        link={completionExtraInfoLink}
        image={FuelRewardsComplete}
      />
    </>
  );
};
