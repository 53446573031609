import { configureStore } from "@reduxjs/toolkit";
import { coreReducer } from "domain/core/reducers";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import rootSaga from "store/middleware/saga";

const logger = () => (next) => (action) => {
  console.log("dispatch", action);
  const result = next(action);
  return result;
};

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {
    coreData: coreReducer,
  },
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === "development") {
      // Exclude redux-thunk because we use redux-saga
      return getDefaultMiddleware({
        serializableCheck: false,
        thunk: false,
      }).concat(sagaMiddleware, logger);
    }

    // Exclude redux-thunk because we use redux-saga
    return getDefaultMiddleware({
      serializableCheck: false,
      thunk: false,
    }).concat(sagaMiddleware);
  },
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export default store;
