import React from "react";

import { BaseDialog, BaseDialogProps } from "../Dialog/BaseDialog";

/** Higher-order component (HOC) for wrapping dialogs */
export const HOCDialog = <P extends BaseDialogProps, T>(
  WrappedComponent: React.ComponentType<T>,
) => {
  const MyComponent: React.FC<P & T> = (props) => {
    return (
      <BaseDialog {...props}>
        <WrappedComponent {...props} />
      </BaseDialog>
    );
  };

  return MyComponent;
};
