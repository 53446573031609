import { css } from "styled-components";

export const sizes = {
  xl: 1366,
  lg: 1024,
  md: 870,
  sm: 480,
};

export const media: any = (
  Object.keys(sizes) as Array<keyof typeof sizes>
).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16;
  accumulator[label] = (first: any, ...interpolations: any[]) => css`
    @media (min-width: ${emSize}em) {
      ${css(first, ...interpolations)}
    }
  `;
  return accumulator;
}, {});
