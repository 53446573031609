/* eslint-disable @typescript-eslint/no-shadow */
import {
  Collapse,
  Container,
  FormControl,
  MenuItem,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  DatePicker as MUIDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { addDays, addMonths, addWeeks, format } from "date-fns";
import { ROUTES } from "domain/configuration";
import { updateFlickSignUpAction } from "domain/core/actions";
import { setCurrentFormStep, setUserProperty } from "domain/core/reducers";
import {
  DetailsInputSteps,
  DwellingType,
  ProductName,
  SituationAtAddress,
} from "domain/core/types";
import { media } from "lib/styles";
import { DATE_FORMAT } from "lib/types";
import { sendAnalyticsEvent, sendAnalyticsPageView } from "lib/util";
import moment from "moment";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { styled } from "styled-components";
import { BackButton } from "ui/components/BackButton";
import Button from "ui/components/Button";
import { SingleSelectRadio } from "ui/components/SingleSelectRadio";
import { ContentWrapper } from "ui/components/StyledComponents";
import { EvChargerAddOn } from "ui/compounds/EvChargerAddOn";
import UserDetailsForm from "ui/compounds/UserDetailsForm";

const MAX_DATE = addMonths(new Date(), 3);
const MIN_SWITCH_DATE = addDays(new Date(), 1);
const MIN_MOVE_DATE = addWeeks(new Date(), -2);

interface State {
  situation?: SituationAtAddress;
  medicallyDependent?: boolean;
  moveDate: Date | null;
  switchDate: Date | null;
  dwellingType?: DwellingType;
  errDate?: string;
  errSituation?: string;
  errMedical?: string;
  errDwellingType?: string;
}

const DatePicker = styled(MUIDatePicker)`
  display: flex;
  flex: 1;
  max-width: 100%;
  min-width: 10rem;
  ${media.md`
    max-width: 48%;
  `}
`;

export enum SectionHeaders {
  about_you = "Personal details",
  rewards = "Select your fuel",
  property = "Property details",
}

export const Details: React.FC = () => {
  window.onbeforeunload = () => "You have unsaved changes!";

  const dispatch = useAppDispatch();

  const {
    currentFormStep,
    productMetadata,
    selectedProduct,
    user: { property },
  } = useAppSelector((store) => store.coreData);

  // Section state handlers
  const [inputStep, setInputStep] = useState<DetailsInputSteps>(
    DetailsInputSteps.about_you,
  );
  const [, setFormComplete] = useState<boolean>(false);

  useEffect(() => {
    // get the current step name to ensure the correct form section is displayed
    const [_, reformatCurrentFormStep] = currentFormStep.split("step=");
    setInputStep(DetailsInputSteps[reformatCurrentFormStep]);
  }, [currentFormStep]);

  useEffect(() => {
    if (inputStep === DetailsInputSteps.none) return;
    sendAnalyticsPageView(SectionHeaders[inputStep]);
  }, [inputStep]);

  const handleMovingToPropertyStep = () => {
    dispatch(updateFlickSignUpAction.request());
    dispatch(setCurrentFormStep(`/details?step=${DetailsInputSteps.property}`));
    setInputStep(DetailsInputSteps.property);
  };

  const handlePropertyContinuePress = async () => {
    let moveDate;
    let switchDate;

    if (state.situation === undefined) {
      return setState((state) => ({
        ...state,
        errSituation: "Oops, please select one.",
      }));
    }

    if (!selectingMoveIn) {
      if (
        state.switchDate === null ||
        isNaN(state.switchDate?.getTime() ?? NaN) ||
        moment(state.switchDate).isBefore(moment(MIN_SWITCH_DATE), "day") ||
        moment(state.switchDate).isAfter(moment(MAX_DATE), "day")
      ) {
        return setState((state) => ({
          ...state,
          errDate: "Please select a valid date.",
        }));
      }
      switchDate = format(state.switchDate, DATE_FORMAT);
    } else {
      if (
        state.moveDate === null ||
        isNaN(state.moveDate?.getTime() ?? NaN) ||
        moment(state.moveDate).isBefore(moment(MIN_MOVE_DATE), "day") ||
        moment(state.moveDate).isAfter(moment(MAX_DATE), "day")
      ) {
        return setState((state) => ({
          ...state,
          errDate: "Please select a valid date.",
        }));
      }
      moveDate = format(state.moveDate, DATE_FORMAT);
    }

    if (
      selectedProduct === ProductName.Z_EV &&
      productMetadata.includeEVcharger &&
      !state.dwellingType
    ) {
      return setState((state) => ({
        ...state,
        errDwellingType: "Oops, please select one.",
      }));
    }

    if (state.medicallyDependent === undefined) {
      return setState((state) => ({
        ...state,
        errMedical: "Oops, please select one.",
      }));
    }

    await setFormComplete(true);

    dispatch(
      setUserProperty({
        moveDate,
        switchDate,
        dwellingType: state.dwellingType,
        medicallyDependent: state.medicallyDependent,
        situation: state.situation,
        usageType: property.usageType,
      }),
    );
    await setInputStep(DetailsInputSteps.none);
    dispatch(updateFlickSignUpAction.request());
    // setting the next form step will also route the user to that form step
    dispatch(setCurrentFormStep("/payment"));
  };

  // Form state handlers
  const [state, setState] = useState<State>({
    situation: property.situation,
    dwellingType: property.dwellingType,
    medicallyDependent: property.medicallyDependent,
    moveDate: (property.moveDate && new Date(property.moveDate)) || null,
    switchDate: (property.switchDate && new Date(property.switchDate)) || null,
  });

  const handleSituationChange = useCallback((situation: SituationAtAddress) => {
    setState((state) => ({ ...state, situation, errSituation: "" }));
  }, []);

  const handleDwellingSelectChange = (dwellingValue: string) => {
    if ((Object.values(DwellingType) as string[]).includes(dwellingValue)) {
      setState((state) => ({
        ...state,
        dwellingType: dwellingValue as DwellingType,
        errDwellingType: "",
      }));
      sendAnalyticsEvent("component_interaction", {
        component_name: "Step 4 > Property type field",
        component_type: "form field",
        interaction_type: "select",
        interaction_value: dwellingValue,
      });
    }
  };

  const handleMedicallyDepChange = useCallback((booleanString: string) => {
    const medicallyDependent = booleanString === "true";
    setState((state) => ({ ...state, medicallyDependent, errMedical: "" }));
  }, []);

  const showChargerAddOnWarning = ({ dwellingType }) => {
    return (
      [DwellingType.FLAT_OR_APARTMENT, DwellingType.TERRACE_HOUSE].includes(
        dwellingType,
      ) && productMetadata.includeEVcharger
    );
  };

  const handleMoveDateChange = useCallback((date: Date) => {
    setState((state) => ({ ...state, moveDate: date, errDate: "" }));
  }, []);

  const handleSwitchDateChange = useCallback((date: Date) => {
    setState((state) => ({ ...state, switchDate: date, errDate: "" }));
  }, []);

  const selectingMoveIn = state.situation === "moved_in";

  const renderSwitchDate = useCallback(() => {
    return (
      <Collapse in={state.situation === "switch_provider"}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Switch date"
            format="dd/MM/yyyy"
            maxDate={MAX_DATE}
            minDate={MIN_SWITCH_DATE}
            value={state.switchDate}
            onChange={handleSwitchDateChange}
            slotProps={{
              textField: {
                error: !!state.errDate,
                helperText: state.errDate,
                margin: "normal",
                inputProps: { id: "property-switch-date" },
              },
            }}
          />
        </LocalizationProvider>
      </Collapse>
    );
  }, [state]);

  const renderMoveDate = useCallback(() => {
    return (
      <Collapse in={state.situation === "moved_in"}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Move in date"
            format="dd/MM/yyyy"
            maxDate={MAX_DATE}
            minDate={MIN_MOVE_DATE}
            value={state.moveDate}
            onChange={handleMoveDateChange}
            slotProps={{
              textField: {
                error: !!state.errDate,
                helperText: state.errDate,
                margin: "normal",
                inputProps: { id: "property-move-in-date" },
              },
            }}
          />
        </LocalizationProvider>
      </Collapse>
    );
  }, [state]);

  const PROPERTY_SITUATION_OPTIONS: Array<{
    label: string;
    value: SituationAtAddress;
    isVisible: boolean;
    collapse?: ReactNode;
  }> = [
    {
      label: "I'm moving in, or have just moved in.",
      value: "moved_in",
      isVisible: true,
      collapse: renderMoveDate(),
    },
    {
      label: "I'd like to switch from another provider.",
      value: "switch_provider",
      isVisible: true,
      collapse: renderSwitchDate(),
    },
  ];

  const MEDICALLY_DEPENDENT_OPTIONS: Array<{
    label: string;
    value: string;
    isVisible: boolean;
  }> = [
    {
      label: "Yes",
      value: "true",
      isVisible: true,
    },
    {
      label: "No",
      value: "false",
      isVisible: true,
    },
  ];

  return (
    <>
      <Container component="main">
        <ContentWrapper>
          <Collapse
            in={inputStep === DetailsInputSteps.about_you}
            mountOnEnter
            unmountOnExit
            addEndListener={() => {
              window.scrollTo(0, 0);
            }}
          >
            <BackButton formStep={ROUTES.address} />
            <Typography variant="body2" component="p">
              Step 1 of 3
            </Typography>
            <Typography variant="h3" id="text-personal-details">
              {SectionHeaders[DetailsInputSteps.about_you]}
            </Typography>
            <UserDetailsForm onUserVerified={handleMovingToPropertyStep} />
          </Collapse>
          <Collapse
            mountOnEnter
            unmountOnExit
            addEndListener={() => {
              window.scrollTo(0, 0);
            }}
            in={inputStep === "property"}
          >
            <BackButton formStep={`${ROUTES.details}?step=about_you`} />
            <Typography variant="body2" component="p">
              Step 2 of 3
            </Typography>
            <Typography variant="h3">
              {SectionHeaders[DetailsInputSteps.property]}
            </Typography>
            <form>
              <Typography variant="h4">
                What's your current power situation?
              </Typography>
              <SingleSelectRadio
                options={PROPERTY_SITUATION_OPTIONS}
                onChangeOption={handleSituationChange}
                selectedOptionValue={state.situation ?? ""}
              />
              {state.errSituation && (
                <Typography color="error">{state.errSituation}</Typography>
              )}{" "}
              {selectedProduct === ProductName.Z_EV &&
                productMetadata.includeEVcharger && (
                  <>
                    <Typography variant="h4">
                      What's your property type?
                    </Typography>
                    <FormControl fullWidth sx={{ maxWidth: "500px" }}>
                      <TextField
                        value={state.dwellingType}
                        onChange={(e) =>
                          handleDwellingSelectChange(e.target.value)
                        }
                        select
                        label="Select property type"
                      >
                        <MenuItem value={DwellingType.SINGLE_DETACHED_HOUSE}>
                          Single-detached house
                        </MenuItem>
                        <MenuItem value={DwellingType.SEMI_DETACHED_HOUSE}>
                          Semi-detached house
                        </MenuItem>
                        <MenuItem value={DwellingType.TERRACE_HOUSE}>
                          Terrace house
                        </MenuItem>
                        <MenuItem value={DwellingType.FLAT_OR_APARTMENT}>
                          Flat or Apartment
                        </MenuItem>
                        <MenuItem value={DwellingType.FARMHOUSE_OR_HOMESTEAD}>
                          Farmhouse or Homestead
                        </MenuItem>
                      </TextField>
                      {state.errDwellingType && (
                        <Typography color="error">
                          {state.errDwellingType}
                        </Typography>
                      )}
                      {showChargerAddOnWarning({
                        dwellingType: state.dwellingType,
                      }) && (
                        <>
                          <Typography variant="body1" display="inline">
                            Some property types may not be suitable for an Evnex
                            EV Smart charger, including apartments, rental
                            properties, and some townhouses. Please be advised
                            that if our installers find your property is not
                            suitable for an Evnex EV Smart charger you will
                            still be signed up for our EV plan.
                          </Typography>
                        </>
                      )}
                    </FormControl>
                  </>
                )}
              {selectedProduct === ProductName.Z_EV &&
                productMetadata.includeEVcharger && <EvChargerAddOn />}
              <Typography variant="h4">
                Is anyone at the address medically dependent on electricity?
              </Typography>
              <Typography variant="body1">
                A ‘medically dependent customer’ relies on electricity for
                critical medical support for example, an oxygen machine. If you
                or anyone living with you at your home is dependent on
                electricity for medical reasons, it is important for you to
                contact us to enable us to provide you the right level of
                support.
              </Typography>
              <SingleSelectRadio
                options={MEDICALLY_DEPENDENT_OPTIONS}
                onChangeOption={handleMedicallyDepChange}
                selectedOptionValue={String(state.medicallyDependent)}
              />
              {state.medicallyDependent && (
                <Typography variant="caption" component="p">
                  We'll be in touch.
                </Typography>
              )}
              {state.errMedical && (
                <Typography color="error">{state.errMedical}</Typography>
              )}
              <Button
                variant="contained"
                onClick={handlePropertyContinuePress}
                id="button-next-step-details"
              >
                Continue
              </Button>
            </form>
          </Collapse>
        </ContentWrapper>
      </Container>
    </>
  );
};
